import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Autocomplete, Chip, TextField } from '@mui/material';
import CloudUpload from '../../components/button/Cloudupload';
import {Grid} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { getproduct } from '../../slices/product';
import { createcombo, updatecombo } from '../../slices/combo';
import EditIcon from "@mui/icons-material/Edit";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
  maxHeight: '100vh',
  overflow: 'scroll',
};

export default function Editcombo({setLoader, editData}) {

  const [moreno, setMoreno] = React.useState(1);

  const {products: productData} = useSelector((state) => state.product);

  const options = productData?.map(va =>({id: va._id, label: va.name}))

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const moreData = {
    field_id: 0,
    product_id:"",
    product_name:"",
    qty:""
  }

  const [formdata, setFormdata] = useState({
    name:"",
    combos: [moreData]
  });

  React.useEffect(()=>{
    console.log(editData);
    if(editData){
      setFormdata({name: editData.name, combos: editData.combos.map(va =>{ return  va})});
      setMoreno(editData?.combos[(editData?.combos.length)-1]?.field_id+1)
    }
}, [editData])

  const handleFieldChange = (name, field_id, event) => {
    const newValue = event.target.value;
    setFormdata({ ...formdata, combos: formdata.combos.map(item1=>{
            if (item1.field_id === field_id) {
              return { ...item1, [name]: newValue };
            }
            return item1;
          })
  })
}

const handleChange = (event, newValue, field_id) => {
  const selectedOptionId = newValue ? newValue.id : null;
  const selectedOptionValue = newValue ? newValue.label : null;
    setFormdata({ ...formdata, combos: formdata.combos.map(item1=>{
            if (item1.field_id === field_id) {
              return { ...item1, product_name: selectedOptionValue, product_id: selectedOptionId };
            }
            return item1;
          })
  })
};

const addMoreDetails = () => {
  formdata.combos.push({...moreData, field_id:moreno}) 
  setMoreno(moreno+1);
}

console.log("vasanth", moreno, formdata);

const removeField = ( field_id) =>{
  setMoreno(moreno-1);
  setFormdata( { ...formdata,   combos: formdata.combos.filter(item1=>{
            return (item1.field_id !== field_id) ;
        })
    });
  };

  useEffect(()=>{
    setLoader(true)
      dispatch(getproduct())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formdata.name || (formdata.combos) > 0) {
      window.alert('Please fill out all required fields.');
    } else {
      setLoader(true);
      const i_id = editData._id
      const formData = {...formdata}
      dispatch(updatecombo({i_id, formData}))
      .then(() => {
        setLoader(false);
        setOpen(false);
        setFormdata({name:"", combos: [moreData]})
      })
      .catch(()=>{
        setLoader(false);
      })
    }

  };


  return (
    <div>
<Button
                                        onClick={handleOpen}
                                          sx={{
                                            background: "#3d07dc",
                                            marginRight: 2,
                                            height: '40px',
                                            width:'40px',
                                            minWidth: 0
                                          }}
                                        >
                                          <EditIcon
                                            sx={{ color: "#fff", fontSize: 17 }}
                                          ></EditIcon>
                                        </Button>      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Grid container sx={{paddingBottom: 2}}>
                <Grid xs={6} sx={{textAlign:'start'}}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Combo
          </Typography>
                </Grid>
                <Grid xs={6} sx={{textAlign:'end'}}>
               <CloseIcon onClick={handleClose} sx={{cursor:'pointer'}}></CloseIcon>
                </Grid>
            </Grid>
            <hr></hr>
         <div style={{marginTop: '30px'}}>
                  <Box>
                  <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Combo Name" variant="outlined" 
                  value={formdata?.name}
                  onChange={(e)=> setFormdata({...formdata, name: e.target.value})}
                  />
                  {formdata?.combos?.map((va, index)=>{
              return(
         <Grid key={index} container spacing={2} sx={{alignItems:'center', marginBottom: 3}}>
          <Grid item xs={8}>
          <Autocomplete
          fullWidth
      disablePortal
      id="combo-box-demo"
      options={options}
      value={va?.product_name}
      onChange={(event, newValue) => handleChange(event, newValue, va.field_id)}
      renderInput={(params) => <TextField {...params} label="Product" 

                   />}
    />
          </Grid>
          <Grid item xs={2}>
          <TextField fullWidth id="outlined-basic" label="qty" variant="outlined" value={va?.qty}
          onChange={(e) => handleFieldChange('qty', va.field_id, e)}
          />
          </Grid>
          <Grid item xs={2} sx={{textAlign:'end'}}>
                <Button variant="outlined" onClick={()=> removeField( va.field_id)}>x</Button>
                    </Grid>
         </Grid>
        )
            })}
                  </Box>
         <Box sx={{display:'flex', justifyContent:'start', alignItems:'start', my: 2}}>
                <Button variant='text' onClick={()=>addMoreDetails()}>+ Add Another Line</Button>
                </Box>
         <Button onClick={handleSubmit} size='large' sx={{marginTop: 3, width: '100%'}} variant='contained'> Update</Button>
         </div>
        </Box>
      </Modal>
    </div>
  );
}