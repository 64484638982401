import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import {Grid} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import { useDispatch } from 'react-redux';
import ExcelUpload from '../../components/button/Excelupload';
import { bulkstudent } from '../../slices/student';
import { bulkteacher } from '../../slices/teacher';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
};

export default function Addexcel({setLoader}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [formdata, setFormdata] = React.useState({excel_file:''})

  const dispatch = useDispatch();

  const handleImage = (val) =>{
    setFormdata({...formdata, excel_file:val})
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData();
    formData.append("file", formdata.excel_file)
    for (var pair of formData.entries()) {
      console.log(pair[0]+ ' - ' + pair[1]); 
   }
    setLoader(true);
        dispatch(bulkteacher(formData))
        .unwrap()
        .then(() => {
          setLoader(false);
          setOpen(false);
          window.location.reload();
        })
        .catch(()=>{
          setLoader(false);
        })
}

  return (
    <div>
      <Button fullWidth variant='contained' size='large' onClick={handleOpen}>Bulk Import</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Grid container sx={{paddingBottom: 2}}>
                <Grid item xs={6} sx={{textAlign:'start'}}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
            Upload You Excel
          </Typography>
                </Grid>
                <Grid item xs={6} sx={{textAlign:'end'}}>
               <CloseIcon onClick={handleClose} sx={{cursor:'pointer'}}></CloseIcon>
                </Grid>
            </Grid>
            <hr></hr>
         <div style={{marginTop: '30px'}}>
         <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
         <ExcelUpload handleImage={handleImage}/>
         <Typography sx={{my: 2}}>{formdata?.excel_file?.name}</Typography>
         <Button type='submit' size='large' sx={{ width: '100%'}} variant='contained'>Submit</Button>
         </Box>
         </div>
        </Box>
      </Modal>
    </div>
  );
}