import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const createStudent = async (data) => {
  const response = await axios.post(API_URL + "/student/add", {...data}, { headers: authHeader() });
  return response.data;
};

const getStudent = async () => {
  const response = await axios.get(API_URL + '/student/all', { headers: authHeader() });
  return response.data;
};

const bulkStudent = async (data) => {
  const response = await axios.post(API_URL + '/student/upload', data, { headers: authHeader() });
  return response.data;
};

// const getNewsbyeditorid = async (page) => {
//   const response = await axios.get(API_URL + `news/editor/${user.id}?page=${page}`, { headers: authHeader() });
//   return response.data;
// };

const updateStudent = async (c_id, formData) => {
  const response = await axios.put(API_URL + `/student/update?id=${c_id}`, {...formData}, { headers: authHeader() });
  return response.data;
};

const deleteStudent = async (c_id) => {
  const response = await axios.delete(API_URL + `/student/delete?id=${c_id}`, { headers: authHeader() });
  return response.data;
};


const studentService = {
  createStudent,
  getStudent,
  bulkStudent,
  updateStudent,
  deleteStudent
};

export default studentService