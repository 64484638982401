import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import EventBus from "../utils/EventBus";
import reportService from "../services/report.service";

export const salesreport = createAsyncThunk(
  "api/salesreport",
  async (thunkAPI) => {
    try {
      const data = await reportService.getSalesReport();
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const stockreport = createAsyncThunk(
  "api/stockreport",
  async (thunkAPI) => {
    try {
      const data = await reportService.getStockReport();
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState ={
    salesReport: [],
    stockReport: []
}

const reportSlice = createSlice({
  name: "api",
  initialState,
  extraReducers: {
    [salesreport.fulfilled]: (state, action) => {
      state.salesReport = action.payload.schooldata.result
      return state;
    },
    [stockreport.fulfilled]: (state, action) => {
      state.stockReport = action.payload.schooldata.result
      return state;
    },
  },
});

const { reducer } = reportSlice;
export default reducer;