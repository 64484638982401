import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import CloudUpload from '../../components/button/Cloudupload';
import {Grid} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useDispatch, useSelector } from 'react-redux';
import { getclass } from '../../slices/class';
import { createstudent, updatestudent } from '../../slices/student';
import EditIcon from "@mui/icons-material/Edit";
import { getcategory } from '../../slices/category';
import { updateproduct } from '../../slices/product';
import { getvendor } from '../../slices/vendor';
import { createinventory } from '../../slices/inventory';
import { createpayment } from '../../slices/payment';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
};

export default function Addstock({setLoader, editData}) {

  const [file, setFile] = React.useState(null)

  const [open, setOpen] = React.useState(false);

  const [payment, setPayment] = React.useState(true);

  const {vendors: vendorData} = useSelector((state) => state.vendor);

  const initState = {
    product_id: editData._id,
    vendor_id: "",
    category_id: editData.category_id,
    demaged_product: 0,
    replacement: 0,
    oldStackQty: editData.current_stack,
    newStackQty: 0,
    totalStackQty: 0,
    stackRemainder: 0,
    total_amount: 0,
    payment_amount: 0,
    pending_amount: 0,
    payment_status: "Pending",
    proof:"",
}

const [formdata, setFormdata] = React.useState(initState);

  const dispatch = useDispatch();

  const handleValue = (e, name) => {
    setFormdata({
      ...formdata,
      [name]: e.target.value,
    });
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect(()=>{
    setFormdata(editData);
    dispatch(getvendor())
    .then(()=>{
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  },[])

  const total_Stack = Number(editData.current_stack) + Number(formdata.newStackQty)

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formdata.vendor_id || !formdata.newStackQty || !formdata.total_amount || (payment && !formdata.payment_amount) || (payment && !file)) {
      window.alert('Please fill out all required fields.');
    } else {
      setLoader(true);
      const c_id = editData._id
      const formData = {current_stack: total_Stack}
      dispatch(updateproduct({c_id, formData}))
      dispatch(createinventory({product_id: editData._id,
        vendor_id: formdata.vendor_id,
        category_id: editData.category_id,
        demaged_product: 0,
        replacement: 0,
        oldStackQty: editData.current_stack,
        newStackQty: Number(formdata.newStackQty),
        totalStackQty: total_Stack,
        total_amount: Number(formdata.total_amount),
        pending_amount: formdata?.payment_amount ? Number(formdata.total_amount) - Number(formdata.payment_amount) : Number(formdata.total_amount),
        payment_status: (Number(formdata.total_amount) - Number(formdata.payment_amount) === 0) ? 'Paid' : 'Pending',
        stackRemainder: 0}))
      .then((response) => {
        const inv_id = response?.payload?.schooldata?.result?._id;
        if(payment){
            dispatch(createpayment({
              product_id:editData._id,
              vendor_id: formdata.vendor_id,
              inventory_id: inv_id,
              payment_Amount: formdata.payment_amount,
              proof: file}))
          }
        setLoader(false);
        setOpen(false);
        setFormdata(initState);
        setPayment(true);
      })
      .catch(()=>{
        setLoader(false);
      })
    }

  };

  return (
    <div>
 <Button
                                        onClick={handleOpen}
                                          sx={{
                                            background: "#3d07dc",
                                            marginRight: 2,
                                            minWidth: 0,
                                            color:'#fff'
                                          }}
                                        >
                                          Add Stock
                                        </Button>      
                                        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Grid container sx={{paddingBottom: 2}}>
                <Grid xs={6} sx={{textAlign:'start'}}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Stock
          </Typography>
                </Grid>
                <Grid xs={6} sx={{textAlign:'end'}}>
               <CloseIcon onClick={handleClose} sx={{cursor:'pointer'}}></CloseIcon>
                </Grid>
            </Grid>
            <hr></hr>
         <Box
          style={{marginTop: '30px'}}
          component="form"
      noValidate
      autoComplete="off"
          >
                   <FormControl sx={{marginBottom: 2}} fullWidth>
  <InputLabel required id="demo-simple-select-label">Vendor</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    label="Vendor"
    value={formdata.vendor_id}
    onChange={(e)=>handleValue(e, 'vendor_id')}
  >
  {vendorData?.map((va, index)=>{
    return(
      <MenuItem key={index} value={va._id}>{va.name}</MenuItem>
    )
  })}
  </Select>
</FormControl>
         <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Current Stock" variant="outlined" 
          value={editData.current_stack}
          InputProps={{
            readOnly: true,
          }}
         />
         <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="New Stock" variant="outlined" 
          value={formdata.newStackQty}
            onChange={(e)=>handleValue(e, 'newStackQty')}
         />
         <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Total Stock" variant="outlined" 
          value={(Number(editData.current_stack)+Number(formdata.newStackQty)) || (Number(editData.current_stack))}
          InputProps={{
            readOnly: true,
          }}
         />
                 <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Total Amount" variant="outlined" 
          value={formdata.total_amount}
            onChange={(e)=>handleValue(e, 'total_amount')}
         />
             <FormGroup sx={{marginBottom: 2}}>
      <FormControlLabel control={<Checkbox checked={payment} onChange={(event)=> setPayment(event.target.checked)} />} label="Payment" />
      </FormGroup>
      {payment ?
        <>
        <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Payment Amount" variant="outlined" 
          value={formdata.payment_amount}
            onChange={(e)=>handleValue(e, 'payment_amount')}
         />
         <CloudUpload setLoader={setLoader} file={file} setFile={setFile} />
        </>
        :
        null
      }
         <Button onClick={handleSubmit} type='submit' size='large' sx={{marginTop: 2, width: '100%'}} variant='contained'> Submit</Button>
         </Box>
        </Box>
      </Modal>
    </div>
  );
}