import { Box } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import idframe from '../../assets/images/id_frame.png';

const ImageLoaderComponent = React.memo(({ rowsSelect, addToRefs, setLoader }) => {
  const imgElements = useRef([]);

  console.log(rowsSelect);

  useEffect(() => {
    let isMounted = true; // Flag to track component mounting state

    const loadImage = async (val, index) => {
      const imageId = getImageIdFromUrl(val?.img ? val?.img : "https://skyschool.s3.amazonaws.com/idphoto1723733221716");
      const blob = await urlToBlob(imageId);

      if (blob && isMounted) {
        const blobUrl = URL.createObjectURL(blob);
        const imgElement = imgElements.current[index];

        if (imgElement) {
          imgElement.src = blobUrl || '';
        } else {
          console.error(`Element with ID imgElement${index} not found.`);
        }
        if(index === rowsSelect.length-1){
          setLoader(false);
        }
      }
    };

    if (rowsSelect.length > 0) {
      rowsSelect.forEach((val, index) => {
        loadImage(val, index);
        console.log(index);
      });
    }

    return () => {
      isMounted = false; // Clean up the effect when the component unmounts
    };
  }, [rowsSelect]);

  const urlToBlob = async (imageId) => {
    try {
      const response = await fetch(`https://api.skyschool.org.in/proxy/${imageId}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error('Error fetching the image:', error);
    }
  };

  const getImageIdFromUrl = (url) => {
    if (url) {
      const urlObj = new URL(url);
      const pathSegments = urlObj.pathname.split('/');
      return pathSegments[pathSegments.length - 1];
    }
  };

  return (
    <Box sx={{display:'flex', gap: 2, flexWrap:'wrap'}}>
      {rowsSelect.map((val, index) => (
        <Box ref={addToRefs} width='8cm' height='13cm' sx={{ marginBottom:'40px', position:'relative', minWidth:'8cm'}}>
      <img src={idframe} alt='idframe' width='100%' height='100%'></img>
        <Box sx={{position:'absolute', left:'37%', top:'33%', transform:'translate(-50%, -50%)', zIndex: -1, borderRadius:'15px', border:'5px solid #fff', width:'150px', height:'150px'}}>
        <img
          id={`imgElement${index}`}
          ref={(el) => (imgElements.current[index] = el)} // Save the reference
          alt={`Image ${index}`}
          key={index}
          style={{position:'absolute', width:'155%', filter:'brightness(140%)'}}
        />
      </Box>
      <h5 style={{position:'absolute', left:'14%', top:'37%',transform:'translate(-50%, -50%)' , color:'#000', textAlign:'center'}}>{val?.vanno}</h5>
      <h5 style={{position:'absolute', left:'88%', top:'36%',transform:'translate(-50%, -50%)' , color:'#000', textAlign:'center'}}>{val?.bloodgroup}</h5>
      <h1 style={{position:'absolute', left:'50%', top:'51%',transform:'translate(-50%, -50%)' , color:'#000', width:'90%', textAlign:'center', textTransform:'uppercase', fontSize:'20px'}}>{val?.name}</h1>
      <h2 style={{position:'absolute', left:'50%', top:'56%',transform:'translate(-50%, -50%)' , color:'#000', fontSize:'16px',  width:'90%', textAlign:'center', fontWeight:'600', textTransform:'uppercase'}}>{val?.std}</h2>
      <h5 style={{position:'absolute', left:'50%', top:'59%',transform:'translate(-50%, -50%)' , color:'#000', textAlign:'start', width:'85%'}}>DOB</h5>
      <h5 style={{position:'absolute', left:'50%', top:'64%',transform:'translate(-50%, -50%)' , color:'#000', textAlign:'start', width:'85%'}}>Father Name</h5>
      <h5 style={{position:'absolute', left:'50%', top:'69%',transform:'translate(-50%, -50%)' , color:'#000', textAlign:'start', width:'85%'}}>Phone</h5>
      <h5 style={{position:'absolute', left:'50%', top:'74%',transform:'translate(-50%, -50%)' , color:'#000', textAlign:'start', width:'85%'}}>Address</h5>
      <h5 style={{position:'absolute', left:'80%', top:'59%',transform:'translate(-50%, -50%)' , color:'#000', textAlign:'start', width:'85%', fontWeight:'normal'}}>: {val?.dob}</h5>
      <h5 style={{position:'absolute', left:'80%', top:'64%',transform:'translate(-50%, -50%)' , color:'#000', textAlign:'start', width:'85%', fontWeight:'normal', textTransform:'uppercase'}}>: {val?.fathername}</h5>
      <h5 style={{position:'absolute', left:'80%', top:'69%',transform:'translate(-50%, -50%)' , color:'#000', textAlign:'start', width:'85%', fontWeight:'normal'}}>: {val?.phonenumber}</h5>
      <h5 style={{position:'absolute', left:'80%', top:'74%',transform:'translate(-50%, -50%)' , color:'#000', textAlign:'start', width:'85%', fontWeight:'normal'}}>: {val?.address1}</h5>
      <h5 style={{position:'absolute', left:'82%', top:'77.5%',transform:'translate(-50%, -50%)' , color:'#000', textAlign:'start', width:'85%', fontWeight:'normal'}}>{val?.address2}</h5>
      <h5 style={{position:'absolute', left:'82%', top:'81%',transform:'translate(-50%, -50%)' , color:'#000', textAlign:'start', width:'85%', fontWeight:'normal'}}>{val?.address3}</h5>
      {/* <h5 style={{position:'absolute', left:'78%', top:'75%',transform:'translate(-68%, -15%)' , color:'#000', textAlign:'start', width:'60%', fontWeight:'normal', textTransform:'uppercase', wordBreak: 'break-word',
    whiteSpace: 'normal', overflowWrap: 'break-word'}}>: {val?.address}</h5> */}
    </Box>
      ))}
</Box>
  );
});

export default ImageLoaderComponent;
