import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import EventBus from "../utils/EventBus";
import categoryService from "../services/category.service";


export const createcategory = createAsyncThunk(
  "api/createcategory",
  async (stateData, thunkAPI) => {
    try {
      const data = await categoryService.createCategory(stateData);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getcategory = createAsyncThunk(
  "api/getcategory",
  async (thunkAPI) => {
    try {
      const data = await categoryService.getCategory();
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updatecategory = createAsyncThunk(
  "api/updatecategory",
  async ({c_id, formData}, thunkAPI) => {
    try {
      const data = await categoryService.updateCategory(c_id, formData);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deletecategory = createAsyncThunk(
  "api/deletecategory",
  async ({c_id}, thunkAPI) => {
    try {
      const data = await categoryService.deleteCategory(c_id);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState ={
    categories: [],
}

const categorySlice = createSlice({
  name: "api",
  initialState,
  extraReducers: {
    [createcategory.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.categories.push(action.payload.schooldata.result)
      return state;
    },
    [getcategory.fulfilled]: (state, action) => {
      state.categories = action.payload.schooldata.result
      return state;
    },
    [updatecategory.fulfilled]: (state, action) => {
      const {
        arg: { c_id },
      } = action.meta;
      if (c_id) {
        state.categories = state.categories.map((item) =>
          item._id === c_id ? action.payload.schooldata.result[0] : item
        );
      };
    },
    [deletecategory.fulfilled]: (state, action) => {
      const {
        arg: { c_id },
      } = action.meta;
      if (c_id) {
        state.categories = state.categories.filter((item) => item._id !== c_id);
      };
    },
  },
});

const { reducer } = categorySlice;
export default reducer;