import React, { useEffect } from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import CustomTable from '../../components/table/VendorTable';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Addstudent from './Addvendor';
import { useDispatch, useSelector } from 'react-redux';
import { getteacher } from '../../slices/teacher';
import { getvendor } from '../../slices/vendor';

const columns = [
  { id: '_id', label: 'Vendor ID', minWidth: 150, align:'start' },
  { id: 'name', label: 'Name', minWidth: 150, align:'start' },
  { id: 'action', label: 'Action', minWidth: 100, align:'center', actionType: [{edit: true, delete: true}] },
];

const rows = [
  {sno: '01', name: 'Cow', class:'Girr', section: 'url', phone_number: '+91 6545644456', address:'Coimbatore'},
  {sno: '02', name: 'Cow', class:'Girr', section: 'url', phone_number: '+91 6545644456', address:'Coimbatore'},
];

const Vendor = ({setLoader}) => {

  const {vendors: vendorData} = useSelector((state) => state.vendor);

  const dispatch = useDispatch();

  const rows = vendorData?.map((va, index)=>{
    return{...va, sno: index+1}
  })

  useEffect(()=>{
    setLoader(true)
      dispatch(getvendor())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content">
        <Box>
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={12} sx={{paddingTop: 0}}>
          <Typography className="headline" variant="h5" sx={{ fontWeight:'bold'}}>Vendor Information</Typography>
          </Grid>
          <Grid item xs={3} sx={{paddingTop: 0}}>
          <TextField
          size='small'
      variant="outlined"
      placeholder='Search'
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{fontSize: 26}}/>
          </InputAdornment>
        ),
      }}
    />
          </Grid>
          <Grid item xs={6} sx={{ paddingTop: 0}}>
          </Grid>
          <Grid item xs={3} sx={{ paddingTop: 0}}>
            <Addstudent  setLoader={setLoader}/>
          </Grid>
          {/* <Grid item xs={3} sx={{paddingTop: 0}}>
            <Button fullWidth variant='contained'>Bulk Import</Button>
          </Grid>
          <Grid item xs={3} sx={{textAlign: 'end', paddingTop: 0}}>
          <Button fullWidth variant='contained'>Export</Button>
          </Grid> */}
          </Grid>
        </Box>
         <Box>
           <CustomTable setLoader={setLoader} columns={columns} rows={vendorData ? rows : []} />
         </Box>
    </div>
  );
}

export default Vendor;