import axios from "axios";
import authHeader from "./auth-header";
import formauthHeader from "./auth-header-from";

const API_URL = process.env.REACT_APP_API_URL;

const createProduct = async (data) => {
  const response = await axios.post(API_URL + "/product/add", {...data}, { headers: authHeader() });
  return response.data;
};

const getProduct = async () => {
  const response = await axios.get(API_URL + '/product/all', { headers: authHeader() });
  return response.data;
};

// const getNewsbyeditorid = async (page) => {
//   const response = await axios.get(API_URL + `news/editor/${user.id}?page=${page}`, { headers: authHeader() });
//   return response.data;
// };

const updateProduct = async (c_id, formData) => {
  const response = await axios.put(API_URL + `/product/update?id=${c_id}`, {...formData}, { headers: authHeader() });
  return response.data;
};

const deleteProduct = async (c_id) => {
  const response = await axios.delete(API_URL + `/product/delete?id=${c_id}`, { headers: authHeader() });
  return response.data;
};


const productService = {
  createProduct,
  getProduct,
  updateProduct,
  deleteProduct
};

export default productService