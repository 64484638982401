import React, { useEffect } from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import CustomTable from '../../components/table/ProductTable';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Addstudent from './Addproduct';
import { useDispatch, useSelector } from 'react-redux';
import { getstudent } from '../../slices/student';
import { getproduct } from '../../slices/product';
import { salesreport } from '../../slices/report';

const columns = [
  { id: 'sno', label: 'S.No', minWidth: 150, align:'start' },
  { id: 'categoryName', label: 'Category', minWidth: 150, align:'start' },
  { id: 'name', label: 'Product Name', minWidth: 150, align:'start' },
  { id: 'margin_price', label: 'Margin Price', minWidth: 150, align:'center' },
  { id: 'profit', label: 'Profit', minWidth: 150, align:'center' },
  { id: 'total_price', label: 'Total', minWidth: 150, align:'center' },
  { id: 'action', label: 'Action', minWidth: 100, align:'center', actionType: [{edit: true, delete: true}] },
];

const rows = [
  {sno: '01', name: 'Cow', class:'Girr', section: 'url', phone_number: '+91 6545644456', address:'Coimbatore'},
  {sno: '02', name: 'Cow', class:'Girr', section: 'url', phone_number: '+91 6545644456', address:'Coimbatore'},
];

const Product = ({setLoader}) => {

  const {products: studentData} = useSelector((state) => state.product);

  const dispatch = useDispatch();

  const rows = studentData?.map((va, index)=>{
    return{...va, sno: index+1}
  })

  useEffect(()=>{
    setLoader(true)
      dispatch(getproduct())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content">
        <Box>
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={12} sx={{paddingTop: 0}}>
          <Typography className="headline" variant="h5" sx={{ fontWeight:'bold'}}>Product Information</Typography>
          </Grid>
          <Grid item xs={3} sx={{paddingTop: 0}}>
          <TextField
          size='small'
      variant="outlined"
      placeholder='Search'
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{fontSize: 26}}/>
          </InputAdornment>
        ),
      }}
    />
          </Grid>
          <Grid item xs={6} sx={{ paddingTop: 0}}>
          </Grid>
          <Grid item xs={3} sx={{ paddingTop: 0}}>
            <Addstudent setLoader={setLoader} />
          </Grid>
          {/* <Grid item xs={3} sx={{paddingTop: 0}}>
            <Button fullWidth variant='contained'>Bulk Import</Button>
          </Grid>
          <Grid item xs={3} sx={{textAlign: 'end', paddingTop: 0}}>
          <Button fullWidth variant='contained'>Export</Button>
          </Grid> */}
          </Grid>
        </Box>
         <Box>
           <CustomTable setLoader={setLoader} columns={columns} rows={studentData ? rows : []} />
         </Box>
    </div>
  );
}

export default Product;