import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import EventBus from "../utils/EventBus";
import productService from "../services/product.service";
import inventoryService from "../services/inventory.service";
import comboService from "../services/combo.service";


export const createcombo = createAsyncThunk(
  "api/createcombo",
  async (stateData, thunkAPI) => {
    try {
      const data = await comboService.createCombos(stateData);
      return { schooldata: data };
    } catch (error) {
      console.log(error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message &&
          error.response.data.msg) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(error.response.data.msg));
      return error.response;
    }
  }
);

export const getcombobyid = createAsyncThunk(
  "api/getcombobyid",
  async (c_id, thunkAPI) => {
    try {
      const data = await comboService.getCombobyID(c_id);
      return { schooldata: data };
    } catch (error) {
      console.log(error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message &&
          error.response.data.msg) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getcombo = createAsyncThunk(
  "api/getcombo",
  async (thunkAPI) => {
    try {
      const data = await comboService.getCombo();
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updatecombo = createAsyncThunk(
  "api/updatecombo",
  async ({i_id, formData}, thunkAPI) => {
    try {
      const data = await comboService.updateCombo(i_id, formData);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deletecombo = createAsyncThunk(
  "api/deletecombo",
  async ({i_id}, thunkAPI) => {
    try {
      const data = await comboService.deleteCombo(i_id);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState ={
    combos: [],
    comboPrice:[],
    message: null
}

const comboSlice = createSlice({
  name: "api",
  initialState,
  extraReducers: {
    [createcombo.fulfilled]: (state, action) => {
      state.combos.push(action.payload.schooldata.result[0])
      return state;
    },
    [getcombobyid.fulfilled]: (state, action) => {
      state.comboPrice = action.payload.schooldata.result
      return state;
    },
    [getcombo.fulfilled]: (state, action) => {
      state.combos = action.payload.schooldata.result
      return state;
    },
    [updatecombo.fulfilled]: (state, action) => {
      const {
        arg: { i_id },
      } = action.meta;
      if (i_id) {
        state.combos = state.combos.map((item) =>
          item._id === i_id ? action.payload.schooldata.result[0] : item
        );
      };
    },
    [deletecombo.fulfilled]: (state, action) => {
      const {
        arg: { i_id },
      } = action.meta;
      if (i_id) {
        state.combos = state.combos.filter((item) => item._id !== i_id);
      };
    },
  },
});

const { reducer } = comboSlice;
export default reducer;