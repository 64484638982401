import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const createCombos = async (data) => {
  const response = await axios.post(API_URL + "/combo/add", {...data}, { headers: authHeader() });
  return response.data;
};

const getCombobyID = async (i_id) => {
  const response = await axios.get(API_URL + `/product/fetchcombo?comboId=${i_id}`, { headers: authHeader() });
  return response.data;
};

const getCombo = async () => {
  const response = await axios.get(API_URL + '/combo/combo', { headers: authHeader() });
  return response.data;
};

const updateCombo = async (i_id, formData) => {
  const response = await axios.put(API_URL + `/combo/update?id=${i_id}`, {...formData}, { headers: authHeader() });
  return response.data;
};

const deleteCombo = async (c_id) => {
  const response = await axios.delete(API_URL + `/combo/delete?id=${c_id}`, { headers: authHeader() });
  return response.data;
};


const comboService = {
  createCombos,
  getCombobyID,
  getCombo,
  updateCombo,
  deleteCombo
};

export default comboService