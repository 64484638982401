import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import EventBus from "../utils/EventBus";
import classService from "../services/class.service";


export const createclass = createAsyncThunk(
  "api/createclass",
  async (stateData, thunkAPI) => {
    try {
      const data = await classService.createClass(stateData);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getclass = createAsyncThunk(
  "api/getclass",
  async (thunkAPI) => {
    try {
      const data = await classService.getClass();
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateclass = createAsyncThunk(
  "api/updateclass",
  async ({c_id, formData}, thunkAPI) => {
    try {
      const data = await classService.updateClass(c_id, formData);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteclass = createAsyncThunk(
  "api/deleteclass",
  async ({c_id}, thunkAPI) => {
    try {
      const data = await classService.deleteClass(c_id);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState ={
    classes: [],
}

const classSlice = createSlice({
  name: "api",
  initialState,
  extraReducers: {
    [createclass.fulfilled]: (state, action) => {
      state.classes.push(action.payload.schooldata.result)
      return state;
    },
    [getclass.fulfilled]: (state, action) => {
      state.classes = action.payload.schooldata.result
      return state;
    },
    [updateclass.fulfilled]: (state, action) => {
      const {
        arg: { c_id },
      } = action.meta;
      if (c_id) {
        state.classes = state.classes.map((item) =>
          item._id === c_id ? action.payload.schooldata.result[0] : item
        );
      };
    },
    [deleteclass.fulfilled]: (state, action) => {
      const {
        arg: { c_id },
      } = action.meta;
      if (c_id) {
        state.classes = state.classes.filter((item) => item._id !== c_id);
      };
    },
  },
});

const { reducer } = classSlice;
export default reducer;