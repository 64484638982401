import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from 'axios';
import authHeader from '../../services/auth-header';

const API_URL = process.env.REACT_APP_API_URL;


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function CloudUpload({setFile, file, setLoader, placeholder}) {
  

  const handleFileChange = async (event) => {
    console.log(file);
    setLoader(true);
      try {
        const formData = new FormData();
        formData.append('image', event.target.files[0]);
        formData.append('filename',  `idphoto${Date.now()}`)
        const response = await axios.post(API_URL + '/idupload', formData, { headers: authHeader() });
        // setFile(URL.createObjectURL(event.target.files[0]));
        setLoader(false);
        setFile(response.data.url);
      } catch (error) {
        setLoader(false)
      } finally {
        setLoader(false)
      }
  };

  return (
    <Button sx={{width: '100%', py: 4,}} size='large' component="label" variant="outlined" startIcon={!file && <CloudUploadIcon />}>
      {!file ? (placeholder ? placeholder : 'Upload Proof') :
      <img src={file} width={100} alt='img'></img>}
      <VisuallyHiddenInput onChange={handleFileChange} type="file" />
    </Button>
  );
}