import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import EventBus from "../utils/EventBus";
import categoryService from "../services/group.service";


export const creategroup = createAsyncThunk(
  "api/creategroup",
  async (stateData, thunkAPI) => {
    try {
      const data = await categoryService.createCategory(stateData);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getgroup = createAsyncThunk(
  "api/getgroup",
  async (thunkAPI) => {
    try {
      const data = await categoryService.getCategory();
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updategroup = createAsyncThunk(
  "api/updategroup",
  async ({c_id, formData}, thunkAPI) => {
    try {
      const data = await categoryService.updateCategory(c_id, formData);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deletegroup = createAsyncThunk(
  "api/deletegroup",
  async ({c_id}, thunkAPI) => {
    try {
      const data = await categoryService.deleteCategory(c_id);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState ={
    groups: [],
}

const groupSlice = createSlice({
  name: "api",
  initialState,
  extraReducers: {
    [creategroup.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.groups.push(action.payload.schooldata.result)
      return state;
    },
    [getgroup.fulfilled]: (state, action) => {
      state.groups = action.payload.schooldata.result
      return state;
    },
    [updategroup.fulfilled]: (state, action) => {
      const {
        arg: { c_id },
      } = action.meta;
      if (c_id) {
        state.groups = state.groups.map((item) =>
          item._id === c_id ? action.payload.schooldata.result[0] : item
        );
      };
    },
    [deletegroup.fulfilled]: (state, action) => {
      const {
        arg: { c_id },
      } = action.meta;
      if (c_id) {
        state.groups = state.groups.filter((item) => item._id !== c_id);
      };
    },
  },
});

const { reducer } = groupSlice;
export default reducer;