import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, Typography, Paper, Divider, FormControl, InputLabel, Select, MenuItem, Autocomplete, TextField, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import CustomTable from '../../components/table/SalesTable';
import Addcategory from './VerifyOpen';
import { useDispatch, useSelector } from "react-redux";
import { getcategory, getclass } from "../../slices/class";
import DeleteIcon from "@mui/icons-material/Delete";
import { getcombo, getcombobyid } from '../../slices/combo';
import { getstudent } from '../../slices/student';
import { getteacher } from '../../slices/teacher';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { getproduct } from '../../slices/product';
import VerifyOpen from './VerifyOpen';
import { getsales } from '../../slices/sales';
import SearchButton from '../../components/search/Search';

const columns = [
  { id: 'name', label: 'Name', minWidth: 150, align:'start' },
  { id: 'class_name', label: 'Class Name', minWidth: 150, align:'start' },
  { id: 'section', label: 'Section', minWidth: 150, align:'start' },
  { id: 'totalAmount', label: 'Total Amount', minWidth: 150, align:'start' },
  { id: 'status', label: 'Status', minWidth: 150, align:'start' },
];

const rows = [
  {sno: '01', class: 'LKG', section: 'A, B, C, D'},
];

const ManageSales = ({setLoader}) => {

  const [status, setStatus] = React.useState('All');

  const [filteredData, setFilteredData] = React.useState();

  const [usertype, setUsertype] = React.useState('Student');

  const [sections, setSections] = React.useState([]);

  const [option, setOptions] = React.useState([]);

  const [items, setItems] = useState([]);

  const [bookstatus, setBookStatus] = useState([]);

  const [comboitems, setComboItems] = useState([]);

  const {classes: classData} = useSelector((state) => state.class);

  const {students: studentData} = useSelector((state) => state.student);

  const {teachers: teacherData} = useSelector((state) => state.teacher);

  const {sales: billingData} = useSelector((state) => state.sales);

  const salesData = billingData?.filter(ab => !ab.pastOrder);

  const initialState = {
    user_type:"",
    class_id:"",
    class_name:"",
    section:"",
    name:"",
    phone:"",
    address:"",
    combo_name:"",
    combo_id:"",
    payment_method:"",
    itemData:[items],
    totalAmount:"",
  }

  const [formdata, setFormdata] = React.useState(initialState);

React.useEffect(()=>{
  if(formdata?.class_id){
    setFormdata({...formdata, class_name: (classData?.filter(ab => ab._id === formdata?.class_id)[0]?.name) })
    setSections((classData?.filter(ab => ab._id === formdata?.class_id)[0]?.section))
  }
}, [formdata?.class_id])

React.useEffect(()=>{
  if(usertype === 'Student'){
    if(formdata?.class_id )
    setOptions((studentData?.filter(ab => (ab.class_id === formdata?.class_id) && (ab.section === formdata?.section))))
  }
  if(usertype === 'Teacher'){
    if(formdata?.class_id )
    setOptions((teacherData?.filter(ab => (ab.class_id === formdata?.class_id))))
  }
}, [formdata?.class_id, formdata?.section])

console.log(option);

const handleFieldChange = (name, product_name, event) => {
  const newValue = event.target.value;
  console.log(name, product_name, event);
  setItems(items.map(item1=>{
          if (item1.name === product_name ) {
            return { ...item1, [name]: newValue };
          }
          return item1;
        })
      )
}

React.useEffect(()=>{
  setLoader(true);
  dispatch(getclass())
  dispatch(getstudent())
  dispatch(getteacher())
  dispatch(getsales())
  .then(()=>{
    setLoader(false);
  })
  .catch(()=>{
    setLoader(false);
  })
},[])

const handleValue = (e, name) => {
  setFormdata({
    ...formdata,
    [name]: e.target.value,
  });
}

  const handleChange = (event) => {
    setUsertype(event.target.value);
  };

  const {combos: combosData} = useSelector((state) => state.combo);

  const {products: productData} = useSelector((state) => state.product);

  const {comboPrice: combosFinalData} = useSelector((state) => state.combo);

  useEffect(()=>{

    if(combosFinalData){
      setComboItems(combosFinalData)
    }

  }, [combosFinalData])


  console.log(formdata);

  const dispatch = useDispatch();

  const handleStudentChange = (event, newValue) => {
    const address = newValue ? newValue.address : null;
    const phone = newValue ? newValue.phone : null;
    const name = newValue ? newValue.label : null;
    setFormdata({ ...formdata, name: name, phone: phone, address: address})
  };

  const handleComboChange = (event, newValue) => {
    const id = newValue ? newValue.id : null;
    const name = newValue ? newValue.label : null;
    setFormdata({ ...formdata, combo_name: name, combo_id: id})
    setLoader(true)
    dispatch(getcombobyid(id))
    .then(() => {
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  };

  const handleProductChange = (event, newValue) => {
    setItems([...items, {name: newValue.label, qty: newValue.qty, total_price: newValue.total_price, _id: newValue._id}])
  };

  const handleItemDelete = (item_id) => {
      const filteredItem = items.filter (ca => ca._id !== item_id);
      setItems(filteredItem);
  }

  const handleComboItemDelete = () =>{
    setComboItems([]);
    setFormdata({...formdata, combo_name: "", combo_id: ""})
  }

  const subTotal = items?.map((obj) => Number(obj.total_price * obj.qty))
  ?.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue,
    0
  );

  console.log(bookstatus);

  const comboTotal = comboitems?.map((obj) => Number(obj.total_price * obj.qty))
  ?.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue,
    0
  );

  const handleFilterChange = (event) => {
    setStatus(event.target.value);
    if(event.target.value === 'All'){
      setFilteredData(salesData);
    }
    if(event.target.value === 'Pending'){
      const filtered = salesData.filter(obj =>{return obj.status === 'Pending'});
      setFilteredData(filtered);
    }
    if(event.target.value === 'Completed'){
      const filtered = salesData.filter(obj =>{return obj.status === 'Completed'});
      setFilteredData(filtered);
    }
  };

  const handleSearch = (query) => {
    const filtered = salesData.filter(item => item.name.includes(query));
    setFilteredData(filtered);
  };

  React.useEffect(()=>{
    setFilteredData(salesData);
  }, [salesData])

  useEffect(()=>{
    setLoader(true)
      dispatch(getcombo())
      dispatch(getproduct())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content">
      <Box>
        <Grid container spacing={2} sx={{ paddingBottom: 2}}>
          <Grid item xs={12} sx={{paddingTop: 0}}>
           <Typography variant='h6' sx={{fontWeight:'bold'}} className='headline'>  Manage Sales</Typography>
           <Box sx={{mt: 3, display:'flex'}}>
            <SearchButton handleSearch={handleSearch} placeholder="Search User"/>
            <FormControl size='small' sx={{ width: 300 }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={status}
          onChange={handleFilterChange}
          sx={{p: 0.2}}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Pending">Pending</MenuItem>
          <MenuItem value="Completed">Completed</MenuItem>
        </Select>
      </FormControl>
           </Box>
           <Box sx={{mt: 3}}>
           <CustomTable setLoader={setLoader} columns={columns} rows={filteredData ? filteredData : []}/>
           </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
      </Box>
    </div>
  );
}

export default ManageSales;