import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import CloudUpload from '../../components/button/Cloudupload';
import {Grid} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useDispatch, useSelector } from 'react-redux';
import { getclass } from '../../slices/class';
import { createstudent, updatestudent } from '../../slices/student';
import EditIcon from "@mui/icons-material/Edit";

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
};

export default function Editstudent({setLoader, editData}) {

  const [open, setOpen] = React.useState(false);
  const [sections, setSections] = React.useState([]);

  const {classes: classData} = useSelector((state) => state.class);

  const [formdata, setFormdata] = React.useState({
    name:"",
    class_id:"",
    section:"",
    phone:"",
    address:""
});

  React.useEffect(()=>{
      if(formdata.class_id){
        setSections((classData?.filter(ab => ab._id === formdata?.class_id)[0]?.section))
      }
  }, [formdata.class_id])

  const dispatch = useDispatch();

  const handleValue = (e, name) => {
    setFormdata({
      ...formdata,
      [name]: e.target.value,
    });
  }

  React.useEffect(()=>{
    setLoader(true);
    setFormdata(editData);
    dispatch(getclass())
    .then(()=>{
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  },[])

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formdata.name || !formdata.class_id || !formdata.section || !formdata.phone || !formdata.address) {
      window.alert('Please fill out all required fields.');
    } else {
      setLoader(true);
      const c_id = editData._id
      const formData = {...formdata}
      dispatch(updatestudent({c_id, formData}))
      .then(() => {
        setLoader(false);
        setOpen(false);
      })
      .catch(()=>{
        setLoader(false);
      })
    }

  };

  return (
    <div>
 <Button
                                        onClick={handleOpen}
                                          sx={{
                                            background: "#3d07dc",
                                            marginRight: 2,
                                            height: '40px',
                                            width:'40px',
                                            minWidth: 0
                                          }}
                                        >
                                          <EditIcon
                                            sx={{ color: "#fff", fontSize: 17 }}
                                          ></EditIcon>
                                        </Button>      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Grid container sx={{paddingBottom: 2}}>
                <Grid xs={6} sx={{textAlign:'start'}}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
            Update Student
          </Typography>
                </Grid>
                <Grid xs={6} sx={{textAlign:'end'}}>
               <CloseIcon onClick={handleClose} sx={{cursor:'pointer'}}></CloseIcon>
                </Grid>
            </Grid>
            <hr></hr>
         <Box
          style={{marginTop: '30px'}}
          component="form"
      noValidate
      autoComplete="off"
          >
         <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Name" variant="outlined" 
          value={formdata.name}
          onChange={(e)=>handleValue(e, 'name')}
         />
         <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Phone Number" variant="outlined" 
          value={formdata.phone}
            onChange={(e)=>handleValue(e, 'phone')}
         />
         <FormControl sx={{marginBottom: 2}} fullWidth>
  <InputLabel required id="demo-simple-select-label">Class</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    label="Class"
    value={formdata.class_id}
    onChange={(e)=>handleValue(e, 'class_id')}
  >
  {classData?.map((va, index)=>{
    return(
      <MenuItem key={index} value={va._id}>{va.name}</MenuItem>
    )
  })}
  </Select>
</FormControl>
                 <FormControl sx={{marginBottom: 2}} fullWidth>
  <InputLabel required id="demo-simple-select-label">Section</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    label="Section"
    value={formdata.section}
    onChange={(e)=>handleValue(e, 'section')}
  >
  {sections?.map((va, index)=>{
    return(
      <MenuItem key={index} value={va}>{va}</MenuItem>
    )
  })}
  </Select>
</FormControl>
         <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Address" variant="outlined" 
          value={formdata.address}
    onChange={(e)=>handleValue(e, 'address')}
         />
         <Button onClick={handleSubmit} type='submit' size='large' sx={{marginTop: 2, width: '100%'}} variant='contained'>Update</Button>
         </Box>
        </Box>
      </Modal>
    </div>
  );
}