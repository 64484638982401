import React, { useEffect } from 'react';
import { Box, Grid, Button, Typography, Paper, Divider } from '@mui/material';
import CustomTable from '../../components/table/Table';
import Addcategory from './Addcombo';
import { useDispatch, useSelector } from "react-redux";
import { getcategory } from "../../slices/class";
import DeleteIcon from "@mui/icons-material/Delete";
import { deletecombo, getcombo } from '../../slices/combo';
import Editcombo from './Editcombo';

const columns = [
  { id: 'sno', label: 'S.No', minWidth: 150, align:'start' },
  { id: 'class', label: 'Class', minWidth: 150, align:'start' },
  { id: 'section', label: 'Sections', minWidth: 150, align:'start' },
  { id: 'action', label: 'Action', minWidth: 100, align:'center', actionType: [{edit: true, delete: true}] },
];

const rows = [
  {sno: '01', class: 'LKG', section: 'A, B, C, D'},
];

const Combo = ({setLoader}) => {

  const {combos: combosData} = useSelector((state) => state.combo);

  console.log(combosData);

  const dispatch = useDispatch();

  useEffect(()=>{
    setLoader(true)
      dispatch(getcombo())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  const handleDelete = (id) => {
    setLoader(true);
    const i_id = id
    dispatch(deletecombo({i_id}))
    .then(() => {
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  }

  return (
    <div className="content">
      <Box>
        <Grid container spacing={2} sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={6} sx={{paddingTop: 0}}>
           <Typography variant='h6' sx={{fontWeight:'bold'}} className='headline'>  Manage Combos</Typography>
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'end', paddingTop: 0}}>
            <Addcategory setLoader={setLoader}/>
          </Grid>
        </Grid>
      </Box>
      <Box>
      <Grid container spacing={2}>
      {combosData.map((va, index)=>{
        return(
          <Grid key={index} item xs={4}>
        <Paper sx={{p: 2, boxShadow:'none', border:'1px solid #cacaca'}}>
            <Grid container spacing={2}>
                  <Grid item xs={8}>
                  <Typography variant='h6'>{va?.name}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                  <Editcombo setLoader={setLoader} editData={va}/>
                  </Grid>
                  <Grid item xs={2}>
                  <Button
                                          sx={{
                                            background: "#dc0707",
                                            marginRight: 2,
                                            height: '40px',
                                            width:'40px',
                                            minWidth: 0
                                          }}
                                          onClick={()=> handleDelete(va?._id)}
                                        >
                                          <DeleteIcon
                                            sx={{ color: "#fff", fontSize: 17 }}
                                          ></DeleteIcon>
                                        </Button>
                  </Grid>
              </Grid>
              {va?.combos?.map((ca, index)=>{
                return(
                    <>
                    <Divider sx={{my: 2}}/>
              <Grid container spacing={2}>
                  <Grid item xs={8}>
                      <Typography>{ca.product_name}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                      <Typography>{ca.qty}</Typography>
                  </Grid>
              </Grid>
                    </>
                )
              })}

          </Paper>
        </Grid>
        )
      })}

      </Grid>
      </Box>
    </div>
  );
}

export default Combo;