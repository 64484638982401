import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { salesreport } from '../../slices/report';
import { Box, Button, FormControl, Grid, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import CustomTable from '../../components/table/VendorTable';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const columns = [
  { id: 'sno', label: 'S.No', minWidth: 150, align:'start' },
  { id: 'name', label: 'Student Name', minWidth: 150, align:'start' },
  { id: 'phone', label: 'Phone No', minWidth: 150, align:'start' },
  { id: 'class_name', label: 'Class Name', minWidth: 150, align:'center' },
  { id: 'section', label: 'Section', minWidth: 150, align:'center' },
  { id: 'totalAmount', label: 'Amount', minWidth: 150, align:'center' },
  { id: 'profitPriceSum', label: 'Profit', minWidth: 100, align:'center'},
];

const exportToExcel = (data, fileName) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  saveAs(dataBlob, `${fileName}.xlsx`);
};

const SalesReport = ({setLoader, show}) => {

  const [status, setStatus] = React.useState('All');

  const [filteredData, setFilteredData] = React.useState();

  const [reportid, setReportId] = useState("sales-to-export")

  const {salesReport: reportData} = useSelector((state) => state.report);

  const dispatch = useDispatch();

  const rows = reportData?.map((va, index)=>{
    return{...va, sno: index+1}
  })

  const handleFilterChange = (event) => {
    setStatus(event.target.value);
    const today = new Date().toISOString().split('T')[0];
    if(event.target.value === 'All'){
      setFilteredData(rows);
    }
    if(event.target.value === 'Today'){
      const filtered = rows.filter(obj => obj.createdAt.startsWith(today));
      setFilteredData(filtered);
    }
  };

  React.useEffect(()=>{
    setFilteredData(rows);
  }, [reportData])

  useEffect(()=>{
    setLoader(true)
    dispatch(salesreport())
    .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  const handleExport = () => {
    exportToExcel(filteredData, 'sales_data');
  };


  
  return (
    <div className="content">
    <Box>
    <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems:'center' }}>
      <Grid item xs={8} sx={{paddingTop: 0}}>
      <Typography className="headline" variant="h5" sx={{ fontWeight:'bold'}}>Sales Report</Typography>
      </Grid>
      <Grid item xs={2}>
      <FormControl fullWidth size='small' >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={status}
          onChange={handleFilterChange}
          sx={{p: 0.2}}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Today">Today</MenuItem>
        </Select>
      </FormControl>
      </Grid>
      <Grid item xs={2} sx={{ paddingTop: 0, textAlign:'end', ms: 2}}>
      {/* <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="export-button"
        table={reportid}
        filename="sales-export"
        sheet="sheet1"
        buttonText="Export to Excel"
      /> */}
      <Button variant="contained" onClick={handleExport}>Export To Excel</Button>
      </Grid>
      </Grid>
    </Box>
     <Box>
       <CustomTable reportid={reportid} setLoader={setLoader} columns={columns} rows={filteredData ? filteredData : []} />
     </Box>
</div>
  )
}

export default SalesReport