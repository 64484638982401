// src/Camera.js
import React, { useRef, useState, useEffect } from 'react';
import DeviceSelector from './DeviceSelector';
import axios from 'axios';
import authHeader from '../../services/auth-header';

const API_URL = process.env.REACT_APP_API_URL;

const Camera = ({setFile, setLoader}) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [hasPhoto, setHasPhoto] = useState(false);
  const [selectedDeviceId, setSelectedDeviceId] = useState('');
  const [selectedDevice, setSelectedDevice] = useState('');
  const [stream, setStream] = useState(null);

  useEffect(() => {
    if (selectedDeviceId) {

      navigator.mediaDevices.getUserMedia({ video: { deviceId: { exact: selectedDeviceId } } })
        .then(stream => {
          setStream(stream);
          let video = videoRef.current;
          video.srcObject = stream;
          video.play();
        })
        .catch(err => {
          console.error("error:", err);
        });
    }
  }, [selectedDeviceId]);

  const takePhoto =  () => {
    const width = 640;
    const height = 480;

    let video = videoRef.current;
    let canvas = canvasRef.current;

    canvas.width = width;
    canvas.height = height;

    let context = canvas.getContext('2d');
    // context.filter = "brightness(160%)";
    context.drawImage(video, 0, 0, width, height, 0, 0, canvas.width, canvas.height);

    setLoader(true);

     // Convert canvas to Blob and create a URL
     canvas.toBlob(async (blob) => {
        const file = new File([blob], `idphoto${Date.now()}`, {
          type: 'image/png',
          lastModified: Date.now(),
        });
        const formData = new FormData();
        formData.append('filename',  `idphoto${Date.now()}`)
        formData.append('image', file);
        const response = await axios.post(API_URL + '/idupload', formData, { headers: authHeader() });
        setLoader(false);
        setFile(response.data.url);
      }, 'image/png', 0.95);

      
    setHasPhoto(true);
  };

  const closePhoto = () => {
    let canvas = canvasRef.current;
    let context = canvas.getContext('2d');

    context.clearRect(0, 0, canvas.width, canvas.height);
    setHasPhoto(false);
    setSelectedDeviceId('');
    setSelectedDevice('');

        if (stream) {
            stream.getTracks().forEach(track => track.stop());
            setStream(null);
          }

  };

  return (
    <div className="camera">
      <DeviceSelector selectedDevice={selectedDevice} setSelectedDevice={setSelectedDevice} onSelect={setSelectedDeviceId} />
      <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', gap: 30}}>
      {selectedDeviceId !== '' && 
      <div>
      <video ref={videoRef} className="video"></video>
      <h5 style={{padding:'10px', background:'#000', color:'#fff', textAlign:'center', margin: 0}} onClick={takePhoto}>Snap</h5>
        </div>
      }
      <div>
      <canvas ref={canvasRef} className={"canvas" + (hasPhoto ? ' hasPhoto' : '')}></canvas>
      {hasPhoto &&
        <h5 style={{padding:'10px', background:'#000', color:'#fff', textAlign:'center', margin: 0}} onClick={closePhoto}>Close</h5>}
       </div>
      </div>
    </div>
  );
};

export default Camera;
