import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Chip, TextField } from '@mui/material';
import CloudUpload from '../../components/button/Cloudupload';
import {Grid} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { createclass, updateclass } from '../../slices/class';
import { useNavigate } from 'react-router-dom/dist';
import EditIcon from "@mui/icons-material/Edit";

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
};

export default function Updateclass({setLoader, editData}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState('');

  const [hashtags, setHashtags] = useState([]);

  const [formdata, setFormdata] = useState({
    name:"",
    section: hashtags
  });

  useEffect(()=>{
      if(editData){
        setFormdata(editData);
        setHashtags((editData?.section)?.split(','))
      }
  }, [editData])

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const value = inputValue.trim();
      if (value) {
        setHashtags([...hashtags, value]);
        setInputValue('');
      }
    }
  };

  const handleValue = (e, name) => {
    setFormdata({
      ...formdata,
      [name]: e.target.value,
    });
  }

  const handleDeleteChip = (index) => {
    const updatedHashtags = [...hashtags];
    updatedHashtags.splice(index, 1);
    setHashtags(updatedHashtags);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formdata.name || hashtags.length === 0) {
      window.alert('Please fill out all required fields.');
    } else {
      setLoader(true);
      const c_id = editData._id
      const formData = {...formdata, section: hashtags}
      dispatch(updateclass({c_id, formData}))
      .then(() => {
        setLoader(false);
        setOpen(false);
      })
      .catch(()=>{
        setLoader(false);
      })
    }

  };

  return (
    <div>
                                        <Button
                                        onClick={handleOpen}
                                          sx={{
                                            background: "#3d07dc",
                                            marginRight: 2,
                                            height: '40px',
                                            width:'40px',
                                            minWidth: 0
                                          }}
                                        >
                                          <EditIcon
                                            sx={{ color: "#fff", fontSize: 17 }}
                                          ></EditIcon>
                                        </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Grid container sx={{paddingBottom: 2}}>
                <Grid xs={6} sx={{textAlign:'start'}}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
            Update Class and Section
          </Typography>
                </Grid>
                <Grid xs={6} sx={{textAlign:'end'}}>
               <CloseIcon onClick={handleClose} sx={{cursor:'pointer'}}></CloseIcon>
                </Grid>
            </Grid>
            <hr></hr>
         <div style={{marginTop: '30px'}}>
         <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Class Name" variant="outlined" 
          value={formdata.name}
                onChange={(e)=>handleValue(e, 'name')}

         />
         <div>
      <TextField
        label="Sections"
        variant="outlined"
        fullWidth
        value={inputValue}
        InputLabelProps={{ required: true }}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        placeholder="Press Enter to add section"
      />
      <div style={{marginTop: 10}}>
        {hashtags?.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onDelete={() => handleDeleteChip(index)}
            sx={{marginRight: 1, marginBottom: 1}}
          />
        ))}
      </div>
    </div>
         <Button onClick={handleSubmit} size='large' sx={{marginTop: 3, width: '100%'}} variant='contained'>Update</Button>
         </div>
        </Box>
      </Modal>
    </div>
  );
}