import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import EventBus from "../utils/EventBus";
import productService from "../services/product.service";
import inventoryService from "../services/inventory.service";
import paymentService from "../services/payment.service";
import vendorService from "../services/vendor.service";


export const createpayment = createAsyncThunk(
  "api/createpayment",
  async (stateData, thunkAPI) => {
    try {
      const data = await paymentService.createPayment(stateData);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getpaymentbyvendor = createAsyncThunk(
  "api/getpaymentbyvendor",
  async (i_id, thunkAPI) => {
    try {
      const data = await paymentService.getPaymentbyvendor(i_id);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getproduct = createAsyncThunk(
  "api/getproduct",
  async (thunkAPI) => {
    try {
      const data = await productService.getProduct();
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateproduct = createAsyncThunk(
  "api/updateproduct",
  async ({c_id, formData}, thunkAPI) => {
    try {
      const data = await productService.updateProduct(c_id, formData);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState ={
    payments: [],
}

const paymentSlice = createSlice({
  name: "api",
  initialState,
  extraReducers: {
    [createpayment.fulfilled]: (state, action) => {
      state.payments.push(action.payload.schooldata.result)
      return state;
    },
    [getpaymentbyvendor.fulfilled]: (state, action) => {
      state.payments = action.payload.schooldata.result
      return state;
    },
    [getproduct.fulfilled]: (state, action) => {
      state.products = action.payload.schooldata.result
      return state;
    },
    [updateproduct.fulfilled]: (state, action) => {
      const {
        arg: { c_id },
      } = action.meta;
      if (c_id) {
        state.products = state.products.map((item) =>
          item._id === c_id ? action.payload.schooldata.result[0] : item
        );
      };
    },
  },
});

const { reducer } = paymentSlice;
export default reducer;