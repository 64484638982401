import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Home } from "@mui/icons-material";
import classNames from "classnames";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import LocalPoliceOutlinedIcon from "@mui/icons-material/LocalPoliceOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import logo from "../../assets/images/logo.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import HomeIcon from '@mui/icons-material/Home';
import CategoryIcon from '@mui/icons-material/Category';
import GroupIcon from '@mui/icons-material/Group';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BadgeIcon from '@mui/icons-material/Badge';
import SettingsIcon from '@mui/icons-material/Settings';
import { useDispatch, useSelector } from "react-redux";

const Sidebar = ({ show, setShow }) => {
  const [selectmenu, setSelectmenu] = useState("");
  const [subnav, setSubnav] = useState('');

  const dispatch = useDispatch();

  const { user: userData } = useSelector((state) => state.auth);

  console.log(userData);

  const side_menus = [
    {
      menuName: "Dashboard",
      icon: SpaceDashboardIcon ,
      route: "/",
    },
    {
      menuName: "Sales",
      icon: SpaceDashboardIcon ,
      route: "/sales",
    },
    {
      menuName: "Past Sales",
      icon: SpaceDashboardIcon ,
      route: "/pastsale",
    },
    {
      menuName: "Products",
      icon: SpaceDashboardIcon ,
      route: "/product",
    },
    {
      menuName: "Inventory",
      icon: SpaceDashboardIcon ,
      route: "/inventory",
    },
    {
      menuName: "Vendors",
      icon: SpaceDashboardIcon ,
      route: "/vendor",
    },
    // {
    //   menuName: "POS",
    //   icon: HomeIcon,
    //   subNav: [
    //     {
    //       menuName: "New Sale",
    //       route: "/newsale",
    //       // icon: <HomeOutlinedIcon />,
    //     },
    //     {
    //       menuName: "Live Orders",
    //       route: "/liveorder",
    //       // icon: <HomeOutlinedIcon />,
    //     },
    //     {
    //       menuName: "Past Orders",
    //       route: "/pastorder",
    //       // icon: <HomeOutlinedIcon />,
    //     }
    //   ],
    // },
    // {
    //   menuName: "Product Management",
    //   icon: CategoryIcon,
    //   subNav: [
    //     {
    //       menuName: "Product list",
    //       route: "/productlist",
    //       icon: <HomeOutlinedIcon />,
    //     },
    //   ],
    // },
    {
      menuName: "User Management",
      icon: GroupIcon,
      subNav: [
        {
          menuName: "Student Information",
          route: "/student",
          icon: <HomeOutlinedIcon />,
        },
        {
          menuName: "Teacher Information",
          route: "/teacher",
          icon: <HomeOutlinedIcon />,
        },
      ],
    },
    {
      menuName: "Report",
      icon: AssessmentIcon,
      subNav: [
        {
          menuName: "Sales Report",
          route: "/salesReport",
          icon: <HomeOutlinedIcon />,
        },
        {
          menuName: "Stock Report",
          route: "/stockReport",
          icon: <HomeOutlinedIcon />,
        },
      ],
    },
    // {
    //   menuName: "Employee Role Setup",
    //   icon: BadgeIcon,
    //   route: "/rolesetup",
    // },
    {
      menuName: "Setting",
      icon: SettingsIcon,
      subNav: [
        {
          menuName: "Category",
          route: "/category",
          icon: <HomeOutlinedIcon />,
        },
        {
          menuName: "Class and Section",
          route: "/class",
          icon: <HomeOutlinedIcon />,
        },
        {
          menuName: "Combo Sets",
          route: "/combo",
          icon: <HomeOutlinedIcon />,
        },
        {
          menuName: "Groups",
          route: "/group",
          icon: <HomeOutlinedIcon />,
        },
      ],
    },
    {
      menuName: "ID Card",
      icon: SpaceDashboardIcon ,
      route: "/generateid",
    },
  ];

  console.log(subnav)

  const handleMenu = (val) => {
    if(val.subNav && subnav !== val.menuName){
      setSubnav(val.menuName);
    }
    else{
      setSelectmenu(val.menuName);
      setSubnav("");
    }
  };

  const handleSubMenu = (val) => {
      setSelectmenu(val);
  };

  console.log(window.location.pathname);

  useEffect(() => {
    const val = window.location.pathname;
    for (let i = 0; i < side_menus.length; i++) {
      if(side_menus[i].subNav){
        for (let j = 0; j < side_menus[i].subNav.length; j++) {
          if (val === side_menus[i].subNav[j].route) {
            setSubnav(side_menus[i].menuName);
            setSelectmenu(side_menus[i].subNav[j].menuName);
          }  
        }
      }
      else if (val === side_menus[i].route) {
        setSelectmenu(side_menus[i].menuName);
      }
    }
  }, []);

  return (
    <div className="sidebar" style={{ left: show ? 0 : "-270px" }}>
      <Box>
        <img src={logo} alt="logo" width={300} style={{ padding: "20px" }}></img>
        <div>
        {userData?.username === "Billing" ?
        <>
        <Link
                  to="/"
                  style={{ textDecoration: "none" }}
                >
                  <Box
                    className={classNames(
                      "sidemenu",
                      `${window.location.pathname === "/" && "active"}`
                    )}
                    sx={{ py: 1, px: 2 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent:'space-between'
                      }}
                    >
                      <div
                       style={{
                        display: "flex",
                        alignItems: "center"
                       }}
                      >
                      <HomeOutlinedIcon sx={{ marginRight: 1, color: "#fff" }} />
                      <Typography sx={{ color: "#fff", fontSize:'14px' }}>
                        Dashboard
                      </Typography>
                      </div>
                    </div>
                  </Box>
                </Link>
                          <Link
                  to="/bill"
                  style={{ textDecoration: "none" }}
                >
                  <Box
                    className={classNames(
                      "sidemenu",
                      `${window.location.pathname === "/bill" && "active"}`
                    )}
                    sx={{ py: 1, px: 2 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent:'space-between'
                      }}
                    >
                      <div
                       style={{
                        display: "flex",
                        alignItems: "center"
                       }}
                      >
                      <HomeOutlinedIcon sx={{ marginRight: 1, color: "#fff" }} />
                      <Typography sx={{ color: "#fff", fontSize:'14px' }}>
                        Billing
                      </Typography>
                      </div>
                    </div>
                  </Box>
                </Link>
                <Link
                  to="/pastsale"
                  style={{ textDecoration: "none" }}
                >
                  <Box
                    className={classNames(
                      "sidemenu",
                      `${window.location.pathname === "/pastsale" && "active"}`
                    )}
                    sx={{ py: 1, px: 2 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent:'space-between'
                      }}
                    >
                      <div
                       style={{
                        display: "flex",
                        alignItems: "center"
                       }}
                      >
                      <HomeOutlinedIcon sx={{ marginRight: 1, color: "#fff" }} />
                      <Typography sx={{ color: "#fff", fontSize:'14px' }}>
                        Past Sales
                      </Typography>
                      </div>
                    </div>
                  </Box>
                </Link>
                <Link
                  to="/student"
                  style={{ textDecoration: "none" }}
                >
                  <Box
                    className={classNames(
                      "sidemenu",
                      `${window.location.pathname === "/student" && "active"}`
                    )}
                    sx={{ py: 1, px: 2 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent:'space-between'
                      }}
                    >
                      <div
                       style={{
                        display: "flex",
                        alignItems: "center"
                       }}
                      >
                      <HomeOutlinedIcon sx={{ marginRight: 1, color: "#fff" }} />
                      <Typography sx={{ color: "#fff", fontSize:'14px' }}>
                        Student Information
                      </Typography>
                      </div>
                    </div>
                  </Box>
                </Link>
                <Link
                  to="/teacher"
                  style={{ textDecoration: "none" }}
                >
                  <Box
                    className={classNames(
                      "sidemenu",
                      `${window.location.pathname === "/teacher" && "active"}`
                    )}
                    sx={{ py: 1, px: 2 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent:'space-between'
                      }}
                    >
                      <div
                       style={{
                        display: "flex",
                        alignItems: "center"
                       }}
                      >
                      <HomeOutlinedIcon sx={{ marginRight: 1, color: "#fff" }} />
                      <Typography sx={{ color: "#fff", fontSize:'14px' }}>
                        Teacher Information
                      </Typography>
                      </div>
                    </div>
                  </Box>
                </Link>
                <Link
                  to="/salesReport"
                  style={{ textDecoration: "none" }}
                >
                  <Box
                    className={classNames(
                      "sidemenu",
                      `${window.location.pathname === "/salesReport" && "active"}`
                    )}
                    sx={{ py: 1, px: 2 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent:'space-between'
                      }}
                    >
                      <div
                       style={{
                        display: "flex",
                        alignItems: "center"
                       }}
                      >
                      <HomeOutlinedIcon sx={{ marginRight: 1, color: "#fff" }} />
                      <Typography sx={{ color: "#fff", fontSize:'14px' }}>
                        Sales Report
                      </Typography>
                      </div>
                    </div>
                  </Box>
                </Link>
                </>
                :
                userData?.username === "Manage Sales" ?
                <>
                          <Link
                  to="/managesales"
                  style={{ textDecoration: "none" }}
                >
                  <Box
                    className={classNames(
                      "sidemenu",
                      `${window.location.pathname === "/managesales" && "active"}`
                    )}
                    sx={{ py: 1, px: 2 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent:'space-between'
                      }}
                    >
                      <div
                       style={{
                        display: "flex",
                        alignItems: "center"
                       }}
                      >
                      <HomeOutlinedIcon sx={{ marginRight: 1, color: "#fff" }} />
                      <Typography sx={{ color: "#fff", fontSize:'14px' }}>
                        Manage Sales
                      </Typography>
                      </div>
                    </div>
                  </Box>
                </Link>
                <Link
                  to="/pastsale"
                  style={{ textDecoration: "none" }}
                >
                  <Box
                    className={classNames(
                      "sidemenu",
                      `${window.location.pathname === "/pastsale" && "active"}`
                    )}
                    sx={{ py: 1, px: 2 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent:'space-between'
                      }}
                    >
                      <div
                       style={{
                        display: "flex",
                        alignItems: "center"
                       }}
                      >
                      <HomeOutlinedIcon sx={{ marginRight: 1, color: "#fff" }} />
                      <Typography sx={{ color: "#fff", fontSize:'14px' }}>
                        Past Sales
                      </Typography>
                      </div>
                    </div>
                  </Box>
                </Link>
                </>
                :
                userData?.username === "Idcard" ?
                <>
                <Link
                  to="/group"
                  style={{ textDecoration: "none" }}
                >
                  <Box
                    className={classNames(
                      "sidemenu",
                      `${window.location.pathname === "/group" && "active"}`
                    )}
                    sx={{ py: 1, px: 2 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent:'space-between'
                      }}
                    >
                      <div
                       style={{
                        display: "flex",
                        alignItems: "center"
                       }}
                      >
                      <HomeOutlinedIcon sx={{ marginRight: 1, color: "#fff" }} />
                      <Typography sx={{ color: "#fff", fontSize:'14px' }}>
                        Group
                      </Typography>
                      </div>
                    </div>
                  </Box>
                </Link>
                          <Link
                  to="/generateid"
                  style={{ textDecoration: "none" }}
                >
                  <Box
                    className={classNames(
                      "sidemenu",
                      `${window.location.pathname === "/generateid" && "active"}`
                    )}
                    sx={{ py: 1, px: 2 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent:'space-between'
                      }}
                    >
                      <div
                       style={{
                        display: "flex",
                        alignItems: "center"
                       }}
                      >
                      <HomeOutlinedIcon sx={{ marginRight: 1, color: "#fff" }} />
                      <Typography sx={{ color: "#fff", fontSize:'14px' }}>
                        ID Card
                      </Typography>
                      </div>
                    </div>
                  </Box>
                </Link>
                </>
        :
          side_menus.map((val, index) => {
            return (
              <>
                <Link
                  key={index}
                  to={val.route}
                  onClick={() => handleMenu(val)}
                  style={{ textDecoration: "none" }}
                >
                  <Box
                    className={classNames(
                      "sidemenu",
                      `${selectmenu === val.menuName && "active"}`
                    )}
                    sx={{ py: 1, px: 2 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent:'space-between'
                      }}
                    >
                      <div
                       style={{
                        display: "flex",
                        alignItems: "center"
                       }}
                      >
                      <val.icon sx={{ marginRight: 1, color: "#fff" }} />
                      <Typography sx={{ color: "#fff", fontSize:'14px' }}>
                        {val.menuName}
                      </Typography>
                      </div>
                      {val.subNav &&
                        (subnav === val.menuName ? (
                          <KeyboardArrowUpIcon sx={{ color: "#fff", textAlign:'end', }} />
                        ) : (
                          <KeyboardArrowDownIcon sx={{ color: "#fff",  textAlign:'end', }} />
                        ))}{" "}
                    </div>
                  </Box>
                </Link>
                {subnav === val.menuName &&
                  val?.subNav?.map((item, index) => {
                    return (
                      <Link
                        key={index}
                        to={item.route}
                        onClick={() => handleSubMenu(item.menuName)}
                        style={{ textDecoration: "none" }}
                      >
                        <Box
                          className={classNames(
                            "sidemenu",
                            `${selectmenu === item.menuName && "active"}`
                          )}
                          sx={{ py: 1, px: 2, marginLeft: 4 }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {/* <val.icon sx={{ marginRight: 1, color: "#fff" }} /> */}
                            <Typography sx={{ marginLeft: 1.5, color: "#fff", fontSize:'14px' }}>
                              {item.menuName}
                            </Typography>
                          </div>
                        </Box>
                      </Link>
                    );
                  })}
              </>
            );
          })}
        </div>
      </Box>
    </div>
  );
};

export default Sidebar;
