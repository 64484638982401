import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import EventBus from "../utils/EventBus";
import teacherService from "../services/teacher.service";


export const createteacher = createAsyncThunk(
  "api/createteacher",
  async (stateData, thunkAPI) => {
    try {
      const data = await teacherService.createTeacher(stateData);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const bulkteacher = createAsyncThunk(
  "api/bulkteacher",
  async (stateData, thunkAPI) => {
    try {
      const data = await teacherService.bulkTeacher(stateData);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getteacher = createAsyncThunk(
  "api/getteacher",
  async (thunkAPI) => {
    try {
      const data = await teacherService.getTeacher();
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateteacher = createAsyncThunk(
  "api/updateteacher",
  async ({c_id, formData}, thunkAPI) => {
    try {
      const data = await teacherService.updateTeacher(c_id, formData);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteteacher = createAsyncThunk(
  "api/deleteteacher",
  async ({c_id}, thunkAPI) => {
    try {
      const data = await teacherService.deleteTeacher(c_id);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState ={
    teachers: [],
}

const teacherSlice = createSlice({
  name: "api",
  initialState,
  extraReducers: {
    [createteacher.fulfilled]: (state, action) => {
      state.teachers.push(action.payload.schooldata.result)
      return state;
    },
    [bulkteacher.fulfilled]: (state, action) => {
      state.teachers.push(action.payload.schooldata.result)
      return state;
    },
    [getteacher.fulfilled]: (state, action) => {
      state.teachers = action.payload.schooldata.result
      return state;
    },
    [updateteacher.fulfilled]: (state, action) => {
      const {
        arg: { c_id },
      } = action.meta;
      if (c_id) {
        state.teachers = state.teachers.map((item) =>
          item._id === c_id ? action.payload.schooldata.result[0] : item
        );
      };
    },
    [deleteteacher.fulfilled]: (state, action) => {
      const {
        arg: { c_id },
      } = action.meta;
      if (c_id) {
        state.teachers = state.teachers.filter((item) => item._id !== c_id);
      };
    },
  },
});

const { reducer } = teacherSlice;
export default reducer;