import React, {useState} from 'react';
import Dashboard from './pages/dashboard/Dashboard.js';
import Category from './pages/category/Category.js';
import { Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import Login from './pages/login/Login.js';
import Register from './pages/register/Register.js';
import Student from './pages/student/Student.js';
import Teacher from './pages/teacher/Teacher.js';
import Class from './pages/class/Class.js';
import Combo from './pages/Combos/Combo.js';
import ProtectedRoute from './utils/ProtectedRoute.js';
import Vendor from './pages/vendor/Vendor.js';
import Product from './pages/product/Product.js';
import { Inventory } from '@mui/icons-material';
import InventoryScreen from './pages/inventory/Inventory.js';
import VendorDetail from './pages/vendor/Vendordetails.js';
import Sales from './pages/sales/Sales.js';
import SalesReport from './pages/salesreport/Salesreport.js';
import ProfitReport from './pages/profitreport/ProfitReport.js';
import SalesDetail from './pages/sales/Salesdetails.js';
import SalesDetail1 from './pages/pastsale/Salesdetails.js';
import PastSales from './pages/pastsale/Sales.js';
import Bill from './pages/bill/Bill.js';
import ManageSales from './pages/managesales/ManageSales.js';
import Idcard from './pages/idcard/Idcard.js';
import Group from './pages/group/Group.js';


const RouteRoute = ({setLoader}) => {

  const [show, setShow] = useState(true);

  return (
    <>
    <Routes>
        <Route path='/login' element={<Login setLoader={setLoader}/>}></Route> 
        <Route path='/register' element={<Register setLoader={setLoader}/>}></Route> 
        <Route path='/' element={<Layout show={show} setShow={setShow}/>}>
        <Route path='/' element={<ProtectedRoute><Dashboard  show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/student' element={<ProtectedRoute><Student show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/teacher' element={<ProtectedRoute><Teacher show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/vendor' >
              <Route index={true} element={<ProtectedRoute><Vendor show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='detail/:vendorID' element={<ProtectedRoute><VendorDetail show={show} setLoader={setLoader}></VendorDetail></ProtectedRoute>}></Route>
        </Route>
        <Route path='/product' element={<ProtectedRoute><Product show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/inventory' element={<ProtectedRoute><InventoryScreen show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/category' element={<ProtectedRoute><Category show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/class' element={<ProtectedRoute><Class show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/combo' element={<ProtectedRoute><Combo show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/group' element={<ProtectedRoute><Group show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/managesales'>
              <Route index={true}  element={<ProtectedRoute><ManageSales show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='detail/:salesID' element={<ProtectedRoute><SalesDetail show={show} setLoader={setLoader}></SalesDetail></ProtectedRoute>}></Route>
        </Route>
        <Route path='/bill'>
              <Route index={true}  element={<ProtectedRoute><Bill show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        </Route>
        <Route path='/sales'>
              <Route index={true}  element={<ProtectedRoute><Sales show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='detail/:salesID' element={<ProtectedRoute><SalesDetail show={show} setLoader={setLoader}></SalesDetail></ProtectedRoute>}></Route>
        </Route>
        <Route path='/pastsale'>
              <Route index={true}  element={<ProtectedRoute><PastSales show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='detail/:salesID' element={<ProtectedRoute><SalesDetail1 show={show} setLoader={setLoader}></SalesDetail1></ProtectedRoute>}></Route>
        </Route>
        <Route path='/salesreport' element={<ProtectedRoute><SalesReport show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/stockreport' element={<ProtectedRoute><ProfitReport show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/generateid'>
              <Route index={true}  element={<ProtectedRoute><Idcard show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        </Route>
      </Route>
    </Routes>
    </>
  )
}

export default RouteRoute;