import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import CloudUpload from '../../components/button/Cloudupload';
import {Grid} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useDispatch, useSelector } from 'react-redux';
import { getclass } from '../../slices/class';
import { createteacher } from '../../slices/teacher';
import { createvendor } from '../../slices/vendor';

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
};

export default function Addteacher({setLoader}) {
  const [open, setOpen] = React.useState(false);

  const initState = {
    name:"",
}
  
  const [formdata, setFormdata] = React.useState(initState);

const dispatch = useDispatch();

const handleValue = (e, name) => {
  setFormdata({
    ...formdata,
    [name]: e.target.value,
  });
}

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formdata.name ) {
      window.alert('Please fill out all required fields.');
    } else {
      setLoader(true);
      dispatch(createvendor({...formdata}))
      .then(() => {
        setLoader(false);
        setOpen(false);
        setFormdata(initState)
      })
      .catch(()=>{
        setLoader(false);
      })
    }

  };

  return (
    <div>
      <Button variant='contained' fullWidth size='large' onClick={handleOpen}>Add Vendor <AddCircleOutlineIcon sx={{ ml: 2 }}/></Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Grid container sx={{paddingBottom: 2}}>
                <Grid xs={6} sx={{textAlign:'start'}}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
            New Vendor
          </Typography>
                </Grid>
                <Grid xs={6} sx={{textAlign:'end'}}>
               <CloseIcon onClick={handleClose} sx={{cursor:'pointer'}}></CloseIcon>
                </Grid>
            </Grid>
            <hr></hr>
            <Box
          style={{marginTop: '30px'}}
          component="form"
      noValidate
      autoComplete="off"
          >
         <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Vendor Name" variant="outlined" 
          value={formdata.name}
          onChange={(e)=>handleValue(e, 'name')}
         />
         <Button onClick={handleSubmit} type='submit' size='large' sx={{marginTop: 2, width: '100%'}} variant='contained'> Submit</Button>
         </Box>
        </Box>
      </Modal>
    </div>
  );
}