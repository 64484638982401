import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import EventBus from "../utils/EventBus";
import productService from "../services/product.service";
import inventoryService from "../services/inventory.service";


export const createinventory = createAsyncThunk(
  "api/createinventory",
  async (stateData, thunkAPI) => {
    try {
      const data = await inventoryService.createInventory(stateData);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getinventorybyvendor = createAsyncThunk(
  "api/getinventorybyvendor",
  async (i_id, thunkAPI) => {
    try {
      const data = await inventoryService.getInventorybyVendor(i_id);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getproduct = createAsyncThunk(
  "api/getproduct",
  async (thunkAPI) => {
    try {
      const data = await productService.getProduct();
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateinventory = createAsyncThunk(
  "api/updateinventory",
  async ({i_id, formData}, thunkAPI) => {
    try {
      const data = await inventoryService.updateInventory(i_id, formData);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState ={
    inventories: [],
}

const inventorySlice = createSlice({
  name: "api",
  initialState,
  extraReducers: {
    [createinventory.fulfilled]: (state, action) => {
      state.inventories.push(action.payload.schooldata.result)
      return state;
    },
    [getinventorybyvendor.fulfilled]: (state, action) => {
      state.inventories = action.payload.schooldata.result
      return state;
    },
    [getproduct.fulfilled]: (state, action) => {
      state.products = action.payload.schooldata.result
      return state;
    },
    [updateinventory.fulfilled]: (state, action) => {
      const {
        arg: { i_id },
      } = action.meta;
      if (i_id) {
        state.inventories = state.inventories.map((item) =>
          item._id === i_id ? action.payload.schooldata.result[0] : item
        );
      };
    }
  },
});

const { reducer } = inventorySlice;
export default reducer;