import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import CloudUpload from '../../components/button/Cloudupload';
import {Grid} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useDispatch, useSelector } from 'react-redux';
import { getclass } from '../../slices/class';
import { createstudent, updatestudent } from '../../slices/student';
import EditIcon from "@mui/icons-material/Edit";
import { getcategory } from '../../slices/category';
import { updateproduct } from '../../slices/product';
import { getvendor } from '../../slices/vendor';
import { createinventory, updateinventory } from '../../slices/inventory';
import { createpayment } from '../../slices/payment';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
};

export default function Addstock({setLoader, editData}) {

  const [file, setFile] = React.useState(null)

  const [open, setOpen] = React.useState(false);

  const initState = {
    product_id: editData.product_id,
    vendor_id: editData.vendor_id,
    inventory_id: editData._id,
    payment_Amount: '',
    proof: file
}

const [formdata, setFormdata] = React.useState(initState);

  const dispatch = useDispatch();

  const handleValue = (e, name) => {
    setFormdata({
      ...formdata,
      [name]: e.target.value,
    });
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formdata.payment_Amount) {
      window.alert('Please fill out all required fields.');
    } else {
      setLoader(true);
      const i_id = editData._id;
      const formData = {
        pending_amount: Number(editData.pending_amount) - Number(formdata.payment_Amount),
        payment_status: (Number(editData.pending_amount) - Number(formdata.payment_Amount) === 0) ? 'Paid' : 'Pending',
      }
      dispatch(updateinventory({i_id, formData}))
      dispatch(createpayment({
        product_id: editData.product_id,
        vendor_id: editData.vendor_id,
        inventory_id: editData._id,
        payment_Amount: formdata.payment_Amount,
        proof: file}))
      .then(() => {
        setLoader(false);
        setOpen(false);
        setFormdata(initState);
        setFile(null);
        // window.location.reload();
      })
      .catch(()=>{
        setLoader(false);
      })
    }

  };

  return (
    <div>
 <Button
                                        onClick={handleOpen}
                                          sx={{
                                            background: "#3d07dc",
                                            marginRight: 2,
                                            minWidth: 0,
                                            color:'#fff'
                                          }}
                                        >
                                          Add Payment
                                        </Button>      
                                        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Grid container sx={{paddingBottom: 2}}>
                <Grid xs={6} sx={{textAlign:'start'}}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Payment
          </Typography>
                </Grid>
                <Grid xs={6} sx={{textAlign:'end'}}>
               <CloseIcon onClick={handleClose} sx={{cursor:'pointer'}}></CloseIcon>
                </Grid>
            </Grid>
            <hr></hr>
         <Box
          style={{marginTop: '30px'}}
          component="form"
      noValidate
      autoComplete="off"
          >
        <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Payment Amount" variant="outlined" 
          value={formdata.payment_Amount}
            onChange={(e)=>handleValue(e, 'payment_Amount')}
         />
         <CloudUpload setLoader={setLoader} file={file} setFile={setFile} />
         <Button onClick={handleSubmit} type='submit' size='large' sx={{marginTop: 2, width: '100%'}} variant='contained'> Submit</Button>
         </Box>
        </Box>
      </Modal>
    </div>
  );
}