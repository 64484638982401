import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";
import App from "./App";
import { ThemeProvider, createTheme } from '@mui/material/styles';

const font =  "'DM Sans', sans-serif";

const theme = createTheme({
  palette: {
    primary: {
      main: '#178500',
      light: '#f5f3ff',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform:'none',
          fontSize: '16px',
          boxShadow: 'none'
        },
      },
    },
  },
  typography: {
    fontFamily: font,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
  <Provider store={store}>
  <App />
</Provider>
</ThemeProvider>,
);