import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const createCategory = async (data) => {
  const response = await axios.post(API_URL + "/group/add", {...data}, { headers: authHeader() });
  return response.data;
};

const getCategory = async () => {
  const response = await axios.get(API_URL + '/group/all', { headers: authHeader() });
  return response.data;
};

// const getNewsbyeditorid = async (page) => {
//   const response = await axios.get(API_URL + `news/editor/${user.id}?page=${page}`, { headers: authHeader() });
//   return response.data;
// };

const updateCategory = async (c_id, formData) => {
  const response = await axios.put(API_URL + `/group/update?id=${c_id}`, {...formData}, { headers: authHeader() });
  return response.data;
};

const deleteCategory = async (c_id) => {
  const response = await axios.delete(API_URL + `/group/delete?id=${c_id}`, { headers: authHeader() });
  return response.data;
};


const groupService = {
  createCategory,
  getCategory,
  updateCategory,
  deleteCategory
};

export default groupService