import React, { useEffect } from 'react';
import { Box, Grid, Button, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import CustomTable from '../../components/table/IdcardTable';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { getstudent } from '../../slices/student';
import Addexcel from './Addexcel';
import SearchButton from '../../components/search/Search';
import { getgroup } from '../../slices/group';
import { getidstudent } from '../../slices/idcard';

const columns = [
  { id: 'ano', label: 'Ad.no', minWidth: 50, align:'start' },
  { id: 'img', label: 'Photo', minWidth: 50, align:'start' },
  { id: 'name', label: 'Name', minWidth: 100, align:'start' },
  { id: 'dob', label: 'DOB', minWidth: 50, align:'start' },
  { id: 'std', label: 'STD', minWidth: 50, align:'center' },
  { id: 'fathername', label: 'Father Name', minWidth: 100, align:'center' },
  { id: 'address1', label: 'Address1', minWidth: 150, align:'center' },
  { id: 'address2', label: 'Address2', minWidth: 150, align:'center' },
  { id: 'address3', label: 'Address3', minWidth: 150, align:'center' },
  { id: 'vanno', label: 'Van No', minWidth: 50, align:'center' },
  { id: 'phonenumber', label: 'Phone No', minWidth: 150, align:'center' },
  { id: 'bloodgroup', label: 'Blood Group', minWidth: 50, align:'center' },
  { id: 'action', label: 'Action', minWidth: 100, align:'center', actionType: [{edit: true, delete: true}] },
];

const Idcard = ({setLoader}) => {

  const [filteredData, setFilteredData] = React.useState();

  const {students: studentData} = useSelector((state) => state.idcard);

  const {groups: groupData} = useSelector((state) => state.group);

  const dispatch = useDispatch();

  const rows = studentData?.map((va, index)=>{
    return{...va, sno: index+1}
  })

  const initialState = {
    group_id:"",
  }

  const [formdata, setFormdata] = React.useState(initialState);

  const handleValue = (e, name) => {
    setFormdata({
      ...formdata,
      [name]: e.target.value,
    });
  }

  const handleSearch = () =>{
    setLoader(true)
    dispatch(getidstudent({c_id: formdata.group_id }))
    .then(() => {
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  }

  React.useEffect(()=>{
    setFilteredData(rows);
  }, [studentData])

  useEffect(()=>{
    setLoader(true)
      dispatch(getgroup())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content">
         <Box>
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={12} sx={{paddingTop: 0}}>
          <Typography className="headline" variant="h5" sx={{ fontWeight:'bold'}}>ID Card</Typography>
          </Grid>
          <Grid item xs={3} sx={{paddingTop: 0}}>
         <FormControl size='small' fullWidth>
  <InputLabel required id="demo-simple-select-label">Group</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    label="Group"
    value={formdata.group_id}
    onChange={(e)=>handleValue(e, 'group_id')}
  >
  {groupData?.map((va, index)=>{
    return(
      <MenuItem key={index} value={va._id}>{va.name}</MenuItem>
    )
  })}
  </Select>
</FormControl>
          </Grid>
          {formdata.group_id && 
          <>
          <Grid item xs={3} sx={{paddingTop: 0}}>
            <Addexcel group_id={formdata.group_id} setLoader={setLoader}/>
          </Grid>
          <Grid item xs={3} sx={{textAlign: 'end', paddingTop: 0}}>
          <Button onClick={handleSearch} fullWidth variant='contained'>Search</Button>
          </Grid>
          </>
          }
          </Grid>
        </Box>
        {filteredData?.length > 0 ?
         <Box>
           <CustomTable set setLoader={setLoader} columns={columns} rows={filteredData ? filteredData : []} />
         </Box>
         :
         null
        }
    </div>
  );
}

export default Idcard;