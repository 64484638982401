import React, {useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Autocomplete, Chip, Divider, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import CloudUpload from '../../components/button/Cloudupload';
import {Grid} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { getproduct } from '../../slices/product';
import { createcombo } from '../../slices/combo';
import { useReactToPrint } from 'react-to-print';
import { createsales } from '../../slices/sales';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  maxHeight: '100vh',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
};

export default function PrintOpen({rows}) {

  const componentRef = useRef();

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleSubmit = (e) => {
        handlePrint();
  };

  const date = new Date(rows?.createdAt);

// Extract day, month, and year
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
          const year = date.getFullYear();

  return (
    <div>
      <Button variant='contained' size='large' onClick={handleOpen}>Print Invoice</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Box ref={componentRef} sx={{mt: 2, border:'1px solid #bababa', p: 3}}>
        <Grid container spacing={3} sx={{ paddingBottom: 2}}>
        <Grid item xs={6} sx={{textAlign:'start'}}>
                <Typography variant="h6">Invoice</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={3}>
                <Typography variant="body2">Billed to</Typography>
                <Typography variant="body1">{rows?.name}</Typography>
                <Typography variant="body1">{rows?.class_name} - {rows?.section}</Typography>
                </Grid>
                <Grid item xs={3}>
                <Typography variant="body2">Address</Typography>
                <Typography variant="body1" sx={{wordWrap: "break-word"}}>{rows?.address}</Typography>
                </Grid>
                <Grid item xs={3}>
                <Typography variant="body2">Phone</Typography>
                <Typography variant="body1">{rows?.phone}</Typography>
                </Grid>
                <Grid item xs={3}>
                <Typography variant="body2">Invoice Date</Typography>
                <Typography variant="body1">{`${day}-${month}-${year}`}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{background:'#595959', color:'#fff'}}>Items</TableCell>
            <TableCell align="center" sx={{background:'#595959', color:'#fff'}}>Quantity</TableCell>
            <TableCell align="center" sx={{background:'#595959', color:'#fff'}}>Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {rows?.combo_name !== "" ? 
        <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {rows?.combo_name}
              </TableCell>
              <TableCell align="center" component="th" scope="row">
              1
              </TableCell>
              <TableCell align="center" component="th" scope="row">
                {rows?.totalAmount}
              </TableCell>
            </TableRow>
            :
            null
        }
        {/* {rows?.itemData.map((va, index) => {
          return(
            <TableRow key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {va?.name}
              </TableCell>
              <TableCell align="center" component="th" scope="row">
                {va?.qty}
              </TableCell>
              <TableCell align="center" component="th" scope="row">
                {va?.qty * va?.total_price}
              </TableCell>
            </TableRow>
          )
        })} */}
        </TableBody>
      </Table>
                </Grid>
                <Grid item xs={12}>
                <Divider sx={{mb: 2}}/>           
                </Grid>
            <Grid item xs={6}>
            
            </Grid>
            <Grid item xs={6} sx={{p: 2, background:'#eaeaea', textAlign:'end'}}>
            <Grid container spacing={2}>
          <Grid item xs={8} sx={{textAlign:'start'}}>
              <Typography  variant="body1">Total Amount</Typography>
          </Grid>
          <Grid item xs={4} sx={{textAlign:'end'}}>
              <Typography variant="body1" sx={{fontWeight:'bold'}}>₹ {rows?.totalAmount}</Typography>
          </Grid>
    </Grid>
            </Grid>
        </Grid>
      </Box>
      <Button
            item
            xs={6}
            onClick={handleSubmit}
            variant='contained'
            sx={{mt: 2}}
          >
          Print
          </Button>
        </Box>
      </Modal>
    </div>
  );
}