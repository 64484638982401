import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import classReducer from "./slices/class";
import categoryReducer from "./slices/category";
import groupReducer from "./slices/group";
import studentReducer from "./slices/student";
import idcardReducer from "./slices/idcard";
import teacherReducer from "./slices/teacher";
import vendorReducer from "./slices/vendor";
import productReducer from "./slices/product";
import inventoryReducer from "./slices/inventory";
import paymentReducer from "./slices/payment";
import comboReducer from "./slices/combo";
import salesReducer from "./slices/sales";
import reportReducer from "./slices/report";

const reducer = {
  auth: authReducer,
  message: messageReducer,
  class: classReducer,
  category: categoryReducer,
  group: groupReducer,
  student: studentReducer,
  idcard: idcardReducer,
  teacher: teacherReducer,
  vendor: vendorReducer,
  product: productReducer,
  inventory: inventoryReducer,
  payment: paymentReducer,
  combo: comboReducer,
  sales: salesReducer,
  report: reportReducer
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
})

export default store;