import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getSalesReport = async () => {
  const response = await axios.get(API_URL + '/billing/billingReport', { headers: authHeader() });
  return response.data;
};

const getStockReport = async () => {
  const response = await axios.get(API_URL + '/inventory/fetchReport', { headers: authHeader() });
  return response.data;
};

const reportService = {
  getSalesReport,
  getStockReport
};

export default reportService