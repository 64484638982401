import React from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography } from '@mui/material';
import { getsales } from '../../slices/sales';
import { salesreport } from '../../slices/report';

const Dashboard = ({show, setLoader}) => {

  const dispatch = useDispatch();

  const today = new Date().toISOString().split('T')[0];

  const { user: userData } = useSelector((state) => state.auth);

  const {sales: salesData} = useSelector((state) => state.sales);

  const {salesReport: profitData} = useSelector((state) => state.report);

  const amountTotal = profitData?.map((obj) =>Number(obj?.totalAmount))
  ?.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue,
    0
  );

  const todayamountTotal = profitData?.filter(obj => obj.createdAt.startsWith(today))?.map((obj) =>Number(obj?.totalAmount))
  ?.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue,
    0
  );

  const profitTotal = profitData?.map((obj) =>Number(obj?.profitPriceSum))
  ?.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue,
    0
  );

  const todayprofitTotal = profitData?.filter(obj => obj.createdAt.startsWith(today))?.map((obj) =>Number(obj?.profitPriceSum))
  ?.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue,
    0
  );

  React.useEffect(()=>{
    setLoader(true);
    dispatch(getsales())
    dispatch(salesreport())
    .then(()=>{
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  },[])

  
  return (
    <div className='content'>
      <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h5' sx={{fontWeight:'bold'}}>Today</Typography>
          </Grid>
          <Grid item xs={4}>
                <Box sx={{border:'1px solid #cacaca', p: 3}}>
                <Typography variant='h6'>No of Bill</Typography>
                <Typography  variant='h5' sx={{fontWeight:'bold', mt: 2}}>{salesData?.filter(obj => obj.createdAt.startsWith(today)).length}</Typography>
                </Box>
          </Grid>
          <Grid item xs={4}>
                <Box sx={{border:'1px solid #cacaca', p: 3}}>
                <Typography variant='h6'>Cash Collected</Typography>
                <Typography  variant='h5' sx={{fontWeight:'bold', mt: 2}}>₹ {todayamountTotal?.toFixed(2)}</Typography>
                </Box>
          </Grid>
          <Grid item xs={4}>
          {userData?.username !== "Billing" && 
                <Box sx={{border:'1px solid #cacaca', p: 3}}>
                <Typography variant='h6'>Profit</Typography>
                <Typography  variant='h5' sx={{fontWeight:'bold', mt: 2}}>₹ {todayprofitTotal?.toFixed(2)}</Typography>
                </Box>
          }
          </Grid>
      </Grid>
      <Grid container spacing={3} sx={{mt: 3}}>
          <Grid item xs={12}>
            <Typography variant='h5' sx={{fontWeight:'bold'}}>Over All</Typography>
          </Grid>
          <Grid item xs={4}>
                <Box sx={{border:'1px solid #cacaca', p: 3}}>
                <Typography variant='h6'>No of Bill</Typography>
                <Typography  variant='h5' sx={{fontWeight:'bold', mt: 2}}>{salesData?.length}</Typography>
                </Box>
          </Grid>
          <Grid item xs={4}>
                <Box sx={{border:'1px solid #cacaca', p: 3}}>
                <Typography variant='h6'>Cash Collected</Typography>
                <Typography  variant='h5' sx={{fontWeight:'bold', mt: 2}}>₹ {amountTotal?.toFixed(2)}</Typography>
                </Box>
          </Grid>
          <Grid item xs={4}>
          {userData?.username !== "Billing" && 
                <Box sx={{border:'1px solid #cacaca', p: 3}}>
                <Typography variant='h6'>Profit</Typography>
                <Typography  variant='h5' sx={{fontWeight:'bold', mt: 2}}>₹ {profitTotal?.toFixed(2)}</Typography>
                </Box>
          }
          </Grid>
      </Grid>
    </div>
  )
}

export default Dashboard