// src/DeviceSelector.js
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useState, useEffect } from 'react';

const DeviceSelector = ({ onSelect, selectedDevice, setSelectedDevice }) => {
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    navigator?.mediaDevices?.getUserMedia({ video: true })
    .then(() => {
    navigator.mediaDevices.enumerateDevices()
      .then(deviceInfos => {
        const videoDevices = deviceInfos.filter(device => device.kind === 'videoinput');
        setDevices(videoDevices);
        console.log(videoDevices);
        // if (videoDevices.length > 0) {
        //   setSelectedDevice(videoDevices[0].deviceId);
        //   onSelect(videoDevices[0].deviceId);
        // }
      })
      .catch(err => {
        console.log("Error: ", err);
      })
      .catch(err => console.log('Error accessing user media:', err))
    })
  }, [onSelect]);

  const handleChange = (event) => {
    setSelectedDevice(event.target.value);
    onSelect(event.target.value);
  };

  return (
    <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Select Camera</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={selectedDevice}    
    label="Select Camera"
    onChange={handleChange}
  >
   {devices.map((device, index) => (
          <MenuItem key={index} value={device.deviceId}>{device.label || `Camera ${index + 1}`}</MenuItem>
        ))}
  </Select>
</FormControl>
  );
};

export default DeviceSelector;
