import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import EventBus from "../utils/EventBus";
import idcardService from "../services/idcard.service";


export const bulkidstudent = createAsyncThunk(
  "api/bulkidstudent",
  async (stateData, thunkAPI) => {
    try {
      const data = await idcardService.bulkidStudent(stateData);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getidstudent = createAsyncThunk(
  "api/getidstudent",
  async ({c_id}, thunkAPI) => {
    try {
      const data = await idcardService.getidStudent(c_id);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateidstudent = createAsyncThunk(
  "api/updateidstudent",
  async ({c_id, formData}, thunkAPI) => {
    try {
      const data = await idcardService.updateidStudent(c_id, formData);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteidstudent = createAsyncThunk(
  "api/deleteidstudent",
  async ({c_id}, thunkAPI) => {
    try {
      const data = await idcardService.deleteidStudent(c_id);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState ={
    students: [],
}

const idcardSlice = createSlice({
  name: "api",
  initialState,
  extraReducers: {
    [bulkidstudent.fulfilled]: (state, action) => {
      state.students.push(action.payload.schooldata.result)
      return state;
    },
    [getidstudent.fulfilled]: (state, action) => {
      state.students = action.payload.schooldata.result
      return state;
    },
    [updateidstudent.fulfilled]: (state, action) => {
      const {
        arg: { c_id },
      } = action.meta;
      if (c_id) {
        state.students = state.students.map((item) =>
          item._id === c_id ? action.payload.schooldata.result[0] : item
        );
      };
    },
    [deleteidstudent.fulfilled]: (state, action) => {
      const {
        arg: { c_id },
      } = action.meta;
      if (c_id) {
        state.students = state.students.filter((item) => item._id !== c_id);
      };
    },
  },
});

const { reducer } = idcardSlice;
export default reducer;