import React, { useEffect } from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import CustomTable from '../../components/table/StudentTable';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Addstudent from './Addstudent';
import { useDispatch, useSelector } from 'react-redux';
import { getstudent } from '../../slices/student';
import Addexcel from './Addexcel';
import SearchButton from '../../components/search/Search';

const columns = [
  { id: 'sno', label: 'S.No', minWidth: 150, align:'start' },
  { id: 'name', label: 'Name', minWidth: 150, align:'start' },
  { id: 'className', label: 'Class', minWidth: 150, align:'start' },
  { id: 'section', label: 'Section', minWidth: 150, align:'center' },
  { id: 'phone', label: 'Phone Number', minWidth: 150, align:'center' },
  { id: 'address', label: 'Address', minWidth: 150, align:'center' },
  { id: 'action', label: 'Action', minWidth: 100, align:'center', actionType: [{edit: true, delete: true}] },
];

const rows = [
  {sno: '01', name: 'Cow', class:'Girr', section: 'url', phone_number: '+91 6545644456', address:'Coimbatore'},
  {sno: '02', name: 'Cow', class:'Girr', section: 'url', phone_number: '+91 6545644456', address:'Coimbatore'},
];

const Student = ({setLoader}) => {

  const [filteredData, setFilteredData] = React.useState();

  const {students: studentData} = useSelector((state) => state.student);

  const dispatch = useDispatch();

  const rows = studentData?.map((va, index)=>{
    return{...va, sno: index+1}
  })

  
  const handleSearch = (query) => {
    const filtered = rows.filter(item => item.name.toLowerCase().includes(query));
    setFilteredData(filtered);
  };

  React.useEffect(()=>{
    setFilteredData(rows);
  }, [studentData])

  useEffect(()=>{
    setLoader(true)
      dispatch(getstudent())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  const bulkImport = () =>{

  }

  return (
    <div className="content">
        <Box>
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={12} sx={{paddingTop: 0}}>
          <Typography className="headline" variant="h5" sx={{ fontWeight:'bold'}}>Student Information</Typography>
          </Grid>
          <Grid item xs={3} sx={{paddingTop: 0}}>
          <SearchButton handleSearch={handleSearch} placeholder="Search Student"/>
          </Grid>
          <Grid item xs={3} sx={{ paddingTop: 0}}>
            <Addstudent setLoader={setLoader} />
          </Grid>
          <Grid item xs={3} sx={{paddingTop: 0}}>
            <Addexcel setLoader={setLoader}/>
          </Grid>
          <Grid item xs={3} sx={{textAlign: 'end', paddingTop: 0}}>
          <Button fullWidth variant='contained'>Export</Button>
          </Grid>
          </Grid>
        </Box>
         <Box>
           <CustomTable setLoader={setLoader} columns={columns} rows={filteredData ? filteredData : []} />
         </Box>
    </div>
  );
}

export default Student;