import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const createClass = async (data) => {
  const response = await axios.post(API_URL + "/class/add", {...data}, { headers: authHeader() });
  return response.data;
};

const getClass = async () => {
  const response = await axios.get(API_URL + '/class/class', { headers: authHeader() });
  return response.data;
};

// const getNewsbyeditorid = async (page) => {
//   const response = await axios.get(API_URL + `news/editor/${user.id}?page=${page}`, { headers: authHeader() });
//   return response.data;
// };

const updateClass = async (c_id, formData) => {
  const response = await axios.put(API_URL + `/class/update?id=${c_id}`, {...formData}, { headers: authHeader() });
  return response.data;
};

const deleteClass = async (c_id) => {
  const response = await axios.delete(API_URL + `/class/delete?id=${c_id}`, { headers: authHeader() });
  return response.data;
};


const classService = {
  createClass,
  getClass,
  updateClass,
  deleteClass
};

export default classService