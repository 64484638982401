import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const createPayment = async (data) => {
  const response = await axios.post(API_URL + "/payment/add", {...data}, { headers: authHeader() });
  return response.data;
};

const getPaymentbyvendor = async (i_id) => {
  const response = await axios.get(API_URL + `/payment/paymentByVendor?vendorId=${i_id}`, { headers: authHeader() });
  return response.data;
};

const getProduct = async () => {
  const response = await axios.get(API_URL + '/product/all', { headers: authHeader() });
  return response.data;
};

const updateProduct = async (c_id, formData) => {
  const response = await axios.put(API_URL + `/product/update?id=${c_id}`, {...formData}, { headers: authHeader() });
  return response.data;
};

const deleteProduct = async (c_id) => {
  const response = await axios.delete(API_URL + `/product/delete?id=${c_id}`, { headers: authHeader() });
  return response.data;
};


const paymentService = {
  createPayment,
  getPaymentbyvendor,
  getProduct,
  updateProduct,
  deleteProduct
};

export default paymentService