import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Chip, TextField } from '@mui/material';
import CloudUpload from '../../components/button/Cloudupload';
import {Grid} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { createclass } from '../../slices/class';
import { useNavigate } from 'react-router-dom/dist';

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
};

export default function Addcategory({setLoader}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState('');

  const [hashtags, setHashtags] = useState([]);

  const [formdata, setFormdata] = useState({
    name:"",
    section: hashtags
  });

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const value = inputValue.trim();
      if (value) {
        setHashtags([...hashtags, value]);
        setInputValue('');
      }
    }
  };

  const handleValue = (e, name) => {
    setFormdata({
      ...formdata,
      [name]: e.target.value,
    });
  }

  const handleDeleteChip = (index) => {
    const updatedHashtags = [...hashtags];
    updatedHashtags.splice(index, 1);
    setHashtags(updatedHashtags);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formdata.name || hashtags.length === 0) {
      window.alert('Please fill out all required fields.');
    } else {
      setLoader(true);
      dispatch(createclass({...formdata, section: hashtags}))
      .then(() => {
        setLoader(false);
        setOpen(false);
        setHashtags([]);
        setFormdata({name:"", section:[]})
      })
      .catch(()=>{
        setLoader(false);
      })
    }

  };

  return (
    <div>
      <Button variant='contained' size='large' onClick={handleOpen}>Add Class and Section</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Grid container sx={{paddingBottom: 2}}>
                <Grid xs={6} sx={{textAlign:'start'}}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Class and Section
          </Typography>
                </Grid>
                <Grid xs={6} sx={{textAlign:'end'}}>
               <CloseIcon onClick={handleClose} sx={{cursor:'pointer'}}></CloseIcon>
                </Grid>
            </Grid>
            <hr></hr>
         <div style={{marginTop: '30px'}}>
         <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Class Name" variant="outlined" 
          value={formdata.name}
                onChange={(e)=>handleValue(e, 'name')}

         />
         <div>
      <TextField
        label="Sections"
        variant="outlined"
        fullWidth
        value={inputValue}
        InputLabelProps={{ required: true }}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        placeholder="Press Enter to add section"
      />
      <div style={{marginTop: 10}}>
        {hashtags.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onDelete={() => handleDeleteChip(index)}
            sx={{marginRight: 1, marginBottom: 1}}
          />
        ))}
      </div>
    </div>
         <Button onClick={handleSubmit} size='large' sx={{marginTop: 3, width: '100%'}} variant='contained'> Submit</Button>
         </div>
        </Box>
      </Modal>
    </div>
  );
}