import React, { useEffect } from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import CustomTable from '../../components/table/VendorTable';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Addstudent from './Addvendor';
import { useDispatch, useSelector } from 'react-redux';
import { getteacher } from '../../slices/teacher';
import { getvendor } from '../../slices/vendor';
import { useParams } from 'react-router-dom/dist';
import { getinventorybyvendor } from '../../slices/inventory';
import { getpaymentbyvendor } from '../../slices/payment';
import Addstock from './Addstock';

const columns = [
  { id: 'sno', label: 'S.No', minWidth: 150, align:'start' },
  { id: 'name', label: 'Name', minWidth: 150, align:'start' },
  { id: 'action', label: 'Action', minWidth: 100, align:'center', actionType: [{edit: true, delete: true}] },
];

const rows = [
  {sno: '01', name: 'Cow', class:'Girr', section: 'url', phone_number: '+91 6545644456', address:'Coimbatore'},
  {sno: '02', name: 'Cow', class:'Girr', section: 'url', phone_number: '+91 6545644456', address:'Coimbatore'},
];

const VendorDetail = ({setLoader}) => {

  const params = useParams();

  const {inventories: inventoryData} = useSelector((state) => state.inventory);

  const {payments: paymentData} = useSelector((state) => state.payment);

  console.log(paymentData, inventoryData);

  const dispatch = useDispatch();

  const rows = inventoryData?.map((va, index)=>{
    return{...va, sno: index+1}
  })

  useEffect(()=>{
    setLoader(true)
    const i_id = params.vendorID;
      dispatch(getinventorybyvendor(i_id))
      dispatch(getpaymentbyvendor(i_id))
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content">
        <Box>
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={12} sx={{paddingTop: 0}}>
          <Typography className="headline" variant="h5" sx={{ fontWeight:'bold'}}>Vendor Details</Typography>
          </Grid>
          <Grid item xs={12} sx={{mt: 3}}>
          {rows.map((va, index)=>{
            const date = new Date(va.createdAt);

// Extract day, month, and year
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
          const year = date.getFullYear();
            return(
              <Grid container key={index} sx={{border:'1px solid #cacaca', padding: 2}} spacing={2}>
              <Grid item xs={2}>
                <Typography>Date</Typography>
                <Typography variant='h6'>{`${day}-${month}-${year}`}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>Product Name</Typography>
                <Typography variant='h6'>{va.productName}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>Added Stock</Typography>
                <Typography variant='h6'>{va.newStackQty}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>Total Amount</Typography>
                <Typography variant='h6'>{va.total_amount}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>Pending Amount</Typography>
                <Typography variant='h6'>{va.pending_amount}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>Payment Status</Typography>
                <Typography variant='h6'>{va.payment_status}</Typography>
              </Grid>
              { va.payment_status !== "Paid" ?
              <Grid item xs={12}>
                <Addstock setLoader={setLoader} editData={va}/>
              </Grid>
              :
              null
              }
              {(paymentData.filter(ab => ab.inventory_id === va._id)).map((ca, index)=>{
                const date = new Date(ca.createdAt);

// Extract day, month, and year
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
          const year = date.getFullYear();
                return(
                  <Grid container spacing={2} sx={{padding: 2}}>
                     <Grid item xs={4}>
                     <Typography>Payment Date</Typography>
                <Typography variant='h6'>{`${day}-${month}-${year}`}</Typography>
                     </Grid>
                     <Grid item xs={4}>
                     <Typography>Payment Amount</Typography>
                <Typography variant='h6'>{ca.payment_Amount}</Typography>
                     </Grid>
                     <Grid item xs={4}>
                     <img src={ca.proof} width={100}></img>
                     </Grid>
                  </Grid>
                )
              })}
             </Grid>
            )
          })
          }
          </Grid>
          </Grid>
        </Box>
    </div>
  );
}

export default VendorDetail;