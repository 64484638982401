import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import EventBus from "../utils/EventBus";
import vendorService from "../services/vendor.service";


export const createvendor = createAsyncThunk(
  "api/createvendor",
  async (stateData, thunkAPI) => {
    try {
      const data = await vendorService.createVendor(stateData);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getvendor = createAsyncThunk(
  "api/getvendor",
  async (thunkAPI) => {
    try {
      const data = await vendorService.getVendor();
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updatevendor = createAsyncThunk(
  "api/updatevendor",
  async ({c_id, formData}, thunkAPI) => {
    try {
      const data = await vendorService.updateVendor(c_id, formData);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deletevendor = createAsyncThunk(
  "api/deletevendor",
  async ({c_id}, thunkAPI) => {
    try {
      const data = await vendorService.deleteVendor(c_id);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState ={
    vendors: [],
}

const vendorSlice = createSlice({
  name: "api",
  initialState,
  extraReducers: {
    [createvendor.fulfilled]: (state, action) => {
      state.vendors.push(action.payload.schooldata.result)
      return state;
    },
    [getvendor.fulfilled]: (state, action) => {
      state.vendors = action.payload.schooldata.result
      return state;
    },
    [updatevendor.fulfilled]: (state, action) => {
      const {
        arg: { c_id },
      } = action.meta;
      if (c_id) {
        state.vendors = state.vendors.map((item) =>
          item._id === c_id ? action.payload.schooldata.result[0] : item
        );
      };
    },
    [deletevendor.fulfilled]: (state, action) => {
      const {
        arg: { c_id },
      } = action.meta;
      if (c_id) {
        state.vendors = state.vendors.filter((item) => item._id !== c_id);
      };
    },
  },
});

const { reducer } = vendorSlice;
export default reducer;