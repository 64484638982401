import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const createInventory = async (data) => {
  const response = await axios.post(API_URL + "/inventory/add", {...data}, { headers: authHeader() });
  return response.data;
};

const getInventorybyVendor = async (i_id) => {
  const response = await axios.get(API_URL + `/inventory/inventoryByVendor?vendorId=${i_id}`, { headers: authHeader() });
  return response.data;
};


const getProduct = async () => {
  const response = await axios.get(API_URL + '/product/all', { headers: authHeader() });
  return response.data;
};

const updateInventory = async (i_id, formData) => {
  const response = await axios.put(API_URL + `/inventory/update?id=${i_id}`, {...formData}, { headers: authHeader() });
  return response.data;
};

const deleteProduct = async (c_id) => {
  const response = await axios.delete(API_URL + `/product/delete?id=${c_id}`, { headers: authHeader() });
  return response.data;
};


const inventoryService = {
  createInventory,
  getInventorybyVendor,
  getProduct,
  updateInventory,
  deleteProduct
};

export default inventoryService