import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import CloudUpload from '../../components/button/Cloudupload';
import {Grid} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useDispatch, useSelector } from 'react-redux';
import { getclass } from '../../slices/class';
import { createstudent, updatestudent } from '../../slices/student';
import EditIcon from "@mui/icons-material/Edit";
import { getcategory } from '../../slices/category';
import { updateproduct } from '../../slices/product';

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
};

export default function Editproduct({setLoader, editData}) {

  const [open, setOpen] = React.useState(false);

  const {categories: categoryData} = useSelector((state) => state.category);

  const initState = {
    margin_price: "",
    profit: "",
    total_price: "",
    name: "",
    category_id: "",
}

const [formdata, setFormdata] = React.useState(initState);

  const dispatch = useDispatch();

  const handleValue = (e, name) => {
    setFormdata({
      ...formdata,
      [name]: e.target.value,
    });
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect(()=>{
    setFormdata(editData);
  },[editData])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formdata.name || !formdata.category_id || !formdata.margin_price || !formdata.profit || !formdata.total_price) {
      window.alert('Please fill out all required fields.');
    } else {
      setLoader(true);
      const c_id = editData._id
      const formData = {...formdata}
      dispatch(updateproduct({c_id, formData}))
      .then(() => {
        setLoader(false);
        setOpen(false);
      })
      .catch(()=>{
        setLoader(false);
      })
    }

  };

  return (
    <div>
 <Button
                                        onClick={handleOpen}
                                          sx={{
                                            background: "#3d07dc",
                                            marginRight: 2,
                                            height: '40px',
                                            width:'40px',
                                            minWidth: 0
                                          }}
                                        >
                                          <EditIcon
                                            sx={{ color: "#fff", fontSize: 17 }}
                                          ></EditIcon>
                                        </Button>      
                                        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Grid container sx={{paddingBottom: 2}}>
                <Grid xs={6} sx={{textAlign:'start'}}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Product
          </Typography>
                </Grid>
                <Grid xs={6} sx={{textAlign:'end'}}>
               <CloseIcon onClick={handleClose} sx={{cursor:'pointer'}}></CloseIcon>
                </Grid>
            </Grid>
            <hr></hr>
         <Box
          style={{marginTop: '30px'}}
          component="form"
      noValidate
      autoComplete="off"
          >
                   <FormControl sx={{marginBottom: 2}} fullWidth>
  <InputLabel required id="demo-simple-select-label">Category</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    label="Category"
    value={formdata.category_id}
    onChange={(e)=>handleValue(e, 'category_id')}
  >
  {categoryData?.map((va, index)=>{
    return(
      <MenuItem key={index} value={va._id}>{va.name}</MenuItem>
    )
  })}
  </Select>
</FormControl>
         <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Product Name" variant="outlined" 
          value={formdata.name}
          onChange={(e)=>handleValue(e, 'name')}
         />
         <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Margin Price" variant="outlined" 
          value={formdata.margin_price}
            onChange={(e)=>handleValue(e, 'margin_price')}
         />
         <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Profit" variant="outlined" 
          value={formdata.profit}
    onChange={(e)=>handleValue(e, 'profit')}
         />
        <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Total" variant="outlined" 
          value={formdata.total_price}
    onChange={(e)=>handleValue(e, 'total_price')}
         />
         <Button onClick={handleSubmit} type='submit' size='large' sx={{marginTop: 2, width: '100%'}} variant='contained'> Update</Button>
         </Box>
        </Box>
      </Modal>
    </div>
  );
}