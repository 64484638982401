import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const createSales = async (data) => {
  const response = await axios.post(API_URL + "/billing/add", {...data}, { headers: authHeader() });
  return response.data;
};

const getSales = async () => {
  const response = await axios.get(API_URL + '/billing/billing', { headers: authHeader() });
  return response.data;
};

const getSalesbyid = async (id) => {
  const response = await axios.get(API_URL + `/billing/billingById?id=${id}`, { headers: authHeader() });
  return response.data;
};

const updateSales = async (c_id, formData) => {
  const response = await axios.put(API_URL + `/billing/update?id=${c_id}`, {...formData}, { headers: authHeader() });
  return response.data;
};

const deleteClass = async (c_id) => {
  const response = await axios.delete(API_URL + `/class/delete?id=${c_id}`, { headers: authHeader() });
  return response.data;
};


const salesService = {
  createSales,
  getSales,
  getSalesbyid,
  updateSales,
  deleteClass
};

export default salesService