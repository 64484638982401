import React, { useEffect } from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import CustomTable from '../../components/table/CategoryTable';
import Addcategory from './Addcategory';
import { useDispatch, useSelector } from "react-redux";
import { getcategory } from '../../slices/category';

const columns = [
  { id: 'sno', label: 'S.No', minWidth: 150, align:'start' },
  { id: 'name', label: 'Category', minWidth: 150, align:'start' },
  { id: 'action', label: 'Action', minWidth: 100, align:'center', actionType: [{edit: true, delete: true}] },
];

const Category = ({setLoader}) => {

  const {categories: categoryData} = useSelector((state) => state.category);

  const dispatch = useDispatch();

  const rows = categoryData?.map((va, index)=>{
    return{...va, sno: index+1}
  })

  useEffect(()=>{
    setLoader(true)
      dispatch(getcategory())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])
  return (
    <div className="content">
      <Box>
        <Grid container spacing={2} sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={6} sx={{paddingTop: 0}}>
           <Typography variant='h6' sx={{fontWeight:'bold'}} className='headline'>  Manage Category</Typography>
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'end', paddingTop: 0}}>
            <Addcategory setLoader={setLoader}/>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <CustomTable setLoader={setLoader} columns={columns} rows={categoryData ? rows : []} />
      </Box>
    </div>
  );
}

export default Category;