import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, Typography, Checkbox } from '@mui/material';
import CustomTable from '../../components/table/VendorTable';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { getteacher } from '../../slices/teacher';
import { getvendor } from '../../slices/vendor';
import { useLocation, useParams } from 'react-router-dom/dist';
import { getinventorybyvendor } from '../../slices/inventory';
import { getpaymentbyvendor } from '../../slices/payment';
import { getsalesbyid, updatesales } from '../../slices/sales';
import PrintOpen from './PrintOpen ';

const columns = [
  { id: 'sno', label: 'S.No', minWidth: 150, align:'start' },
  { id: 'name', label: 'Name', minWidth: 150, align:'start' },
  { id: 'action', label: 'Action', minWidth: 100, align:'center', actionType: [{edit: true, delete: true}] },
];

const rows = [
  {sno: '01', name: 'Cow', class:'Girr', section: 'url', phone_number: '+91 6545644456', address:'Coimbatore'},
  {sno: '02', name: 'Cow', class:'Girr', section: 'url', phone_number: '+91 6545644456', address:'Coimbatore'},
];

const SalesDetail = ({setLoader}) => {

  const params = useParams();

  const dispatch = useDispatch();

  const {state} = useLocation();

  const [formdata, setFormdata]= useState();

  const {salesDetail: rows} = useSelector((state) => state.sales);

  useEffect(()=>{
      setFormdata({...rows, bookStatus:rows?.bookStatus?.map((va, index) =>{return {...va, no:  index }})});
  }, [rows])


  const handleChange = (event, no) => {
      setFormdata({ ...formdata, bookStatus: formdata.bookStatus.map(item1=>{
              if (item1.no === no) {
                return { ...item1, status: event.target.checked };
              }
              return item1;
            })
    })
  };

  const handleSubmit = (e) => {
    e.preventDefault();
      setLoader(true);
      const c_id = rows._id
      const formData = {...formdata, status: formdata?.bookStatus.filter(va=> va.status === false).length > 0 ? 'Pending' : 'Completed'}
      dispatch(updatesales({c_id, formData}))
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  };

  useEffect(()=>{
    setLoader(true)
      dispatch(getsalesbyid(params.salesID))
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content">
        <Box>
        <Grid container spacing={3} sx={{ paddingBottom: 2}}>
          <Grid item xs={12} sx={{paddingTop: 0}}>
          <Typography className="headline" variant="h5" sx={{ fontWeight:'bold'}}>Sales Details</Typography>
          </Grid>
          <Grid item xs={12} sx={{mt: 3}}>
              <Grid container sx={{border:'1px solid #cacaca', padding: 2}} spacing={2}>
              <Grid item xs={2}>
                <Typography>Name</Typography>
                <Typography variant='h6'>{rows?.name}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>Class</Typography>
                <Typography variant='h6'>{rows?.class_name} - {rows?.section}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>Combo Name</Typography>
                <Typography variant='h6'>{rows?.combo_name}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>Payment Method</Typography>
                <Typography variant='h6'>{rows?.payment_method}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>Total Amount</Typography>
                <Typography variant='h6'>{rows?.totalAmount}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>Status</Typography>
                <Typography variant='h6'>{rows?.status}</Typography>
              </Grid>
             </Grid>
          </Grid>
          <Grid item xs={8} sx={{ p: 0}}>
              <Box sx={{border:'1px solid #cacaca', padding: 2}} >
              {formdata?.bookStatus?.map((va, index)=>{
                return(
                <Grid container key={index} spacing={2} sx={{p: 2}}>
              <Grid item xs={4}>
                <Typography variant='h6'>{va?.name}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='h6'>{va?.qty}</Typography>
              </Grid>
              <Grid item xs={4}>
              <Checkbox
      checked={va?.status}
      onChange={(event)=>handleChange(event, va.no)}
      inputProps={{ 'aria-label': 'controlled' }}
    />
              </Grid>
             </Grid>
                )
              })}
             </Box>
          </Grid>
          <Grid item xs={4}>
              <PrintOpen rows={rows}/>
          </Grid>
          <Grid item xs={8}>
            <Button fullWidth sx={{py: 2}} variant='contained' onClick={handleSubmit}>Update Sales</Button>
          </Grid>
          </Grid>
        </Box>
    </div>
  );
}

export default SalesDetail;