import React, { useEffect } from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import CustomTable from '../../components/table/Table';
import Addcategory from './Addclass';
import { useDispatch, useSelector } from "react-redux";
import { getcategory, getclass } from "../../slices/class";

const columns = [
  { id: 'sno', label: 'S.No', minWidth: 150, align:'start' },
  { id: 'name', label: 'Class', minWidth: 150, align:'start' },
  { id: 'section', label: 'Sections', minWidth: 150, align:'start' },
  { id: 'action', label: 'Action', minWidth: 100, align:'center', actionType: [{edit: true, delete: true}] },
];


const Class = ({setLoader}) => {

  const {classes: classData} = useSelector((state) => state.class);

  console.log(classData);
  
  const dispatch = useDispatch();

  const rows = classData?.map((va, index)=>{
    return{...va, section: (va.section).join(), sno: index+1}
  })

  useEffect(()=>{
    setLoader(true)
      dispatch(getclass())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content">
      <Box>
        <Grid container spacing={2} sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={6} sx={{paddingTop: 0}}>
           <Typography variant='h6' sx={{fontWeight:'bold'}} className='headline'>  Manage Class and Section</Typography>
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'end', paddingTop: 0}}>
            <Addcategory setLoader={setLoader}/>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <CustomTable columns={columns} setLoader={setLoader} rows={ classData ? rows : [] } />
      </Box>
    </div>
  );
}

export default Class;