import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const createVendor = async (data) => {
  const response = await axios.post(API_URL + "/vendor/add", {...data}, { headers: authHeader() });
  return response.data;
};

const getVendor = async () => {
  const response = await axios.get(API_URL + '/vendor/all', { headers: authHeader() });
  return response.data;
};

// const getNewsbyeditorid = async (page) => {
//   const response = await axios.get(API_URL + `news/editor/${user.id}?page=${page}`, { headers: authHeader() });
//   return response.data;
// };

const updateVendor = async (c_id, formData) => {
  const response = await axios.put(API_URL + `/vendor/update?id=${c_id}`, {...formData}, { headers: authHeader() });
  return response.data;
};

const deleteVendor = async (c_id) => {
  const response = await axios.delete(API_URL + `/vendor/delete?id=${c_id}`, { headers: authHeader() });
  return response.data;
};


const vendorService = {
  createVendor,
  getVendor,
  updateVendor,
  deleteVendor
};

export default vendorService