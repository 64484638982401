import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import EventBus from "../utils/EventBus";
import classService from "../services/class.service";
import salesService from "../services/sales.service";


export const createsales = createAsyncThunk(
  "api/createsales",
  async (stateData, thunkAPI) => {
    try {
      const data = await salesService.createSales(stateData);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getsales = createAsyncThunk(
  "api/getsales",
  async (thunkAPI) => {
    try {
      const data = await salesService.getSales();
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getsalesbyid = createAsyncThunk(
  "api/getsalesbyid",
  async (id, thunkAPI) => {
    try {
      const data = await salesService.getSalesbyid(id);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updatesales = createAsyncThunk(
  "api/updatesales",
  async ({c_id, formData}, thunkAPI) => {
    try {
      const data = await salesService.updateSales(c_id, formData);
      return { schooldata: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(error.response.status);
        if (error.response && error.response.status === 401 && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

// export const deleteclass = createAsyncThunk(
//   "api/deleteclass",
//   async ({c_id}, thunkAPI) => {
//     try {
//       const data = await classService.deleteClass(c_id);
//       return { schooldata: data };
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//         console.log(error.response.status);
//         if (error.response && error.response.status === 401 && error.response.status === 403) {
//           EventBus.dispatch("logout");
//         }
//       thunkAPI.dispatch(setMessage(message));
//       return thunkAPI.rejectWithValue();
//     }
//   }
// );

const initialState ={
    sales: [],
    salesDetail: null
}

const salesSlice = createSlice({
  name: "api",
  initialState,
  extraReducers: {
    [createsales.fulfilled]: (state, action) => {
      state.sales.push(action.payload.schooldata.result)
      return state;
    },
    [getsales.fulfilled]: (state, action) => {
      state.sales = action.payload.schooldata.result
      return state;
    },
    [getsalesbyid.fulfilled]: (state, action) => {
      state.salesDetail = action.payload.schooldata.result[0]
      return state;
    },
    [updatesales.fulfilled]: (state, action) => {
      const {
        arg: { c_id },
      } = action.meta;
      if (c_id) {
        state.sales = state.sales.map((item) =>
          item._id === c_id ? action.payload.schooldata.result[0] : item
        );
        state.salesDetail = c_id ? action.payload.schooldata.result[0] : state.salesDetail
      };
    },
    // [deleteclass.fulfilled]: (state, action) => {
    //   const {
    //     arg: { c_id },
    //   } = action.meta;
    //   if (c_id) {
    //     state.classes = state.classes.filter((item) => item._id !== c_id);
    //   };
    // },
  },
});

const { reducer } = salesSlice;
export default reducer;