import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const createTeacher = async (data) => {
  const response = await axios.post(API_URL + "/teacher/add", {...data}, { headers: authHeader() });
  return response.data;
};

const bulkTeacher = async (data) => {
  const response = await axios.post(API_URL + '/teacher/upload', data, { headers: authHeader() });
  return response.data;
};

const getTeacher = async () => {
  const response = await axios.get(API_URL + '/teacher/all', { headers: authHeader() });
  return response.data;
};

// const getNewsbyeditorid = async (page) => {
//   const response = await axios.get(API_URL + `news/editor/${user.id}?page=${page}`, { headers: authHeader() });
//   return response.data;
// };

const updateTeacher = async (c_id, formData) => {
  const response = await axios.put(API_URL + `/teacher/update?id=${c_id}`, {...formData}, { headers: authHeader() });
  return response.data;
};

const deleteTeacher = async (c_id) => {
  const response = await axios.delete(API_URL + `/teacher/delete?id=${c_id}`, { headers: authHeader() });
  return response.data;
};


const teacherService = {
  createTeacher,
  bulkTeacher,
  getTeacher,
  updateTeacher,
  deleteTeacher
};

export default teacherService